.multi-cta {
	text-align: center;
	margin: 0 0 6rem;
	.btn {
		margin: 0 18px 18px;
    	transition: none;
		&:active {
		margin: 4px 18px 14px;
		}
	}
}
