.blue-standout {
	background-color: $new_text_color;
	@extend %section-margins;
	&__heading {
		@extend %text-column;
		@extend %gutters;
		@include big-heading-font;
		color: $white;
		margin-bottom: 5rem;
	}
	&__content {
		@extend %text-column;
		@extend %gutters;
		* {
			color: #fff;
		}
		:last-child {
			margin-bottom: 0;
		}
	}
}
