.video {
	@extend %four-column;
	@extend %gutters;
	margin-top: 5rem;
	margin-bottom: 6rem;
	.image + &, .video + & {
		margin-top: 0; // when images/videos follow each other, don't let their margins stack
	}
	&__wrap {
		position: relative;
		padding-bottom: 56.25%; /* 16:9 */
	}
	iframe {
		border: none;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}