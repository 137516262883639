.jumplinks {
    &.text {
        margin-bottom: 0;
    }
    p {
        margin-bottom: 1rem;
    }
    svg {
        width: 0.72em;
        height: auto;
        margin-right: 1rem;
        background-image: none;
    }
    a {
        background-image: none;
        span {
            text-decoration: underline;
        }
    }
}
.wysiwyg div.jumplinks {
    margin-bottom: 5rem;
}