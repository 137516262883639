.html {
	@extend %gutters;
	margin-top: 5rem;
	margin-bottom: 6rem;
	&--text {
		@extend %text-column;
	}
	&--two-thirds {
		@extend %four-column;	
	}
	iframe {
		border: none;
	}
}