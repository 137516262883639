.sidebar-card {
	@extend %sidebar-column;
	@extend %gutters;
	padding-top: 1rem;
	margin-top: 5rem;
	margin-bottom: 6rem;
	&__content {
		border-top: 1rem solid $green;
		background-color: $green-10;
		padding: 3rem 3rem 5rem;
		a {
			color: $new_text_color;
			background-image: none;
			text-decoration: underline;
		}
	}
	&--yellow &__content {
		border-top: 1rem solid $green;
		background-color: $green-10;
	}
	&__heading {
		@include body-bold-font;
		margin-bottom: 3rem;
	}
	&__p {
		@include body-font;
	}
	&__dt {
		@include small-heading-font;
	}
	&__dd {
		@include small-text-font;
		&:not(:last-of-type) {
			padding-bottom: 1rem;
			border-bottom: 1px solid $slate-55-rgb;
			margin-bottom: 1rem;
		}
	}
	&__list {
		&__item {
			@include small-text-font;
			&:not(:last-of-type) {
				padding-bottom: 1rem;
				//border-bottom: 1px solid $gray-15;
				margin-bottom: 1rem;
			}
		}
	}
}
