.blockquote {
	@extend %gutters;
	@extend %text-column;
	margin-bottom: 3rem;
	&__text {
		@extend %blockquote-text;
	}
	&__source {
		@extend %blockquote-source;
	}
	.leading-quote & {
		margin-top: 0;
		margin-bottom: 0;
	}
	.blockquote-carousel & {
		margin-left: 0;
		margin-right: 0;
		@include breakpoint(medium) {
			width: 50%;
			float: right;
		}		
	}
}