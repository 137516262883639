.pagination {
	border-top: 1px solid $gray-15;
	padding-top: 3rem;
	@include clearfix;
	&__item {
		float: left;
		&:not(:last-of-type) {
			padding-right: 1rem;
			border-right: 1px solid $gray-15;
			margin-right: 1rem;
		}
	}
}