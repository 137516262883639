.jump-to {
	@include small-text-font;
	border: 1px solid $slate-55-rgb;
	border-width: 1px 0;
	padding: 2rem 0;
	margin-bottom: 5rem !important;
	&__span, &__link {
		margin-right: 1.5rem;
	}
}
.jumplink-intro {
	margin-top: -3rem;
	margin-bottom: 3rem;
}
