$nav-btn-width: 20px;
$nav-btn-height: 18px;
$nav-btn-line-width: 2px;
$nav-btn-padding-v: 3px;
$nav-btn-padding-h: 0;

.JS body {
	padding-top: $header-height-small;
	@include breakpoint($nav-breakpoint) {
		padding-top: $header-height-medium;
	}
}

.site-header {
	background: #fff;
	box-shadow: 0px 0px 20px 0 rgba(0,0,0,.15);

	/******************************************************************************************
	Sticky header
	*******************************************************************************************/

	.JS & {
		z-index: 1000;
		position: absolute;
		left: 0;
		right: 0;
		top: $header-height-small;
		margin-top: -$header-height-small; // slightly odd combo of top and margin-top here is so that transition kicks in right way when hidden/fixed
		@include breakpoint($nav-breakpoint) {
			top: $header-height-medium;
			margin-top: -$header-height-medium;
		}
		&--is-hidden, &--is-fixed {
			position: fixed;
			top: 0 !important;
			bottom: auto!important;
			left: 0;
			right: 0;
			transition: margin .3s;
		}
		&--is-hidden {
			margin-top: -$header-height-small;
			@include breakpoint(smallish) {
				margin-top: -$header-height-medium;
			}
		}
		&--is-fixed {
			margin-top: 0;
		}
	}
	/******************************************************************************************
	Mobile nav
	*******************************************************************************************/

	// logo is full width panel on mobile so nav can be hidden under it
	&__logo {
		@extend %negative-screen-margins;
		@extend %screen-margins-with-gutters;
		padding-top: 2rem;
		padding-bottom: 0;
		background: #fff;
		z-index: 1; // move in front of nav panel
		position: relative;
		@include clearfix;
		&__text {
			display: none;
		}
		&__svg {
			display: block;
			width: 136px;
			height: 70px;
			float: left;
			.halfCircle {
				fill: #1d1d1d;
			}
			.letters {
				fill: #ffffff;
			}
		}
	}

	// hamburger menu

	&__nav-btn {
		cursor: pointer;
		background: url('data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7'); // this is a transparent gif – makes whole nav btn clickable in IE9, rather than just the lines
		position: absolute;
		top: 3.5rem;
		right: $screen-margin-small-with-gutter;
		z-index: 2; // move in front of logo
		&__text {
			@include nav-font;
			color: $new_text_color;
			padding-right: 1.5rem;
			padding-top: .25rem;
			float: left;
		}
		&__lines {
			width: $nav-btn-width;
			height: $nav-btn-height;
			padding: $nav-btn-padding-v $nav-btn-padding-h;
			position: relative;
			float: right;
			top: .5rem;
		}
		&__l {
			width: $nav-btn-width;
			height: $nav-btn-line-width;
			background: $new_text_color;
			position: absolute;
			left: $nav-btn-padding-h;
			opacity: 1;
			transform: rotate(0deg);
			// transition: .25s ease-in-out; // this seems to mess up nav sliding in/out in chrome for some reason!
			&--1 {
				top: $nav-btn-padding-v;
			}
			&--2, &--3 {
				top: calc((#{$nav-btn-height} / 2) - (#{$nav-btn-line-width} / 2) + $nav-btn-padding-v);
			}
			&--4 {
				top: $nav-btn-height - $nav-btn-line-width + $nav-btn-padding-v;
			}
		}
		&--is-active {
			// turn hamburger into a cross
			.site-header__nav-btn__l--1 {
				top: calc((#{$nav-btn-height} / 2) - (#{$nav-btn-line-width} / 2));
				width: 0%;
				left: 50%;
			}
			.site-header__nav-btn__l--2 {
				transform: rotate(45deg);
			}
			.site-header__nav-btn__l--3 {
				transform: rotate(-45deg);
			}
			.site-header__nav-btn__l--4 {
				top: calc((#{$nav-btn-height} / 2) - (#{$nav-btn-line-width} / 2));
				width: 0%;
				left: 50%;
			}
		}
	}
	@include breakpoint(smallish) {
		&__nav-btn {
			right: $screen-margin-smallish-with-gutter;
		}
	}
	@include breakpoint($nav-breakpoint) {
		&__logo {
			float: left;
			padding-top: 1.5rem;
			padding-bottom: 0;
			&__svg {
				width: 156px;
				height: 81px;
			}
		}
		&__nav-btn {
			display: none;
		}
	}
}
