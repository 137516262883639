%label {
	@include body-bold-font;
	display: block;
	margin-bottom: 1rem;
}
%textInput {
	border: 1px solid $green-strong;
	display: block;
	width: 100%;
	padding: 1rem 1.5rem;
	@include box;
	@include body-font;
	box-shadow: inset 0 4px 0 0 $slate-10;
}
%textarea {
	border: 1px solid $gray-30;
	display: block;
	width: 100%;
	padding: 1rem 1.5rem;
	height: 20rem;
	@include box;
	@include body-font;
	box-shadow: inset 0 4px 0 0 $slate-10;
}
%select {
	border: 1px solid $gray-30;
	display: inline-block;
	padding: 1rem 6rem 1rem 1.5rem;
	@include body-font;
	margin-bottom: 3rem;
	background: #fff url(data:image/svg+xml;charset=utf-8;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI0NCIgaGVpZ2h0PSI0OCIgdmlld0JveD0iMCAwIDQ0IDQ4Ij48cGF0aCBmaWxsPSIjNDU0NTQ1IiBkPSJNMTUgMTloMTRsLTcgMTF6Ii8+PC9zdmc+) top right no-repeat;
	-moz-appearance: none; text-indent: 1px; text-overflow: ''; // removes arrow from FF - see http://css-tricks.com/almanac/properties/a/appearance/
	// remove select arrows on IE
	&::-ms-expand {
		display: none;
	}
}

.form {
	&:not(.form--no-padding) {
		@extend %gutters;
		.form-wrap__no-padding & {
			padding-left: 0;
			padding-right: 0; 
		}
	}
	&__required-note {
		@include small-text-font;
		margin-bottom: 3rem;
	}
	&__field {
		clear: both;
		margin-bottom: 3rem;
		&--half {
			width: 50%;
			float: left;
			clear: none;
			@include clearfix;
			@include box;
		}
		&--left {
			padding-right: 1rem;
		}
		&--right {
			padding-left: 1rem;
		}
		.JS &--hidden {
			display: none;
		}
	}
	&__label {
		@extend %label;
		&--required:after {
			content: '*';
		}
		&.hidden {
			display: none;
		}
	}
	&__textInput {
		@extend %textInput;
		&--short {
			width: 12rem;
		}
	}
	&__textarea {
		@extend %textarea;
	}
	&__select {
		@extend %select;
	}
	&__checkbox {
		display: block;
		&__checkbox {
			position: relative;
			float: left;
			font-size: 16px;
			position: relative;
			top: 2px;
			appearance: none;
			width: 24px;
			height: 24px;
			border: 1px solid $green-strong;
			background-color: #ffffff;
			box-shadow: inset 0 4px 0 0 $slate-10;
			&:checked {
				&:before {
					content: '';
					position: absolute;
					top: 2px;
					left: 3px;
					width: 12px;
					height: 8px;
					border-left: 3px solid $green-strong;
					border-bottom: 3px solid $green-strong;
					transform: rotate(-45deg);
				}
			}
		}
		&__text {
			@include body-font;
			display: block;
			margin-left: calc(24px + 8px);
			margin-bottom: 1rem;
		}
	}
	&__radio-group {
		padding-bottom: 2rem;
		&__radio {
			float: left;
			font-size: 16px;
			position: relative;
			top: 2px;
			appearance: none;
			width: 24px;
			height: 24px;
			border-radius: 50%;
			margin-right: 8px;
			border: 1px solid $green-strong;
			background-color: #ffffff;
			box-shadow: inset 0 4px 0 0 $slate-10;
			&:checked {
				&:before {
					content: '';
					position: absolute;
					top: 5px;
					left: 5px;
					width: 12px;
					height: 12px;
					border-radius: 50%;
					background-color: $green-strong;
				}
			}
		}
		&__text {
			@include body-font;
			display: block;
			margin-left: 3rem;
			margin-bottom: 1rem;
		}
	}
	&__image {
		margin: 1rem 0 1rem;
	}
	&__mailing_list {

	}
	&__small-text {
		@include small-text-font;
		margin-bottom: 2rem;
	}
	&__error-message, .freeform-form-errors {
		margin-bottom: 3rem;
		@include body-font;
		color: $error-color;
		p {
			@include body-font;
			color: $error-color;
		}
	}
	&__field.has-error {
		.form__textInput, .form__textarea {
			border-color: $error-color;
		}
	}
	.errors, .freeform-form-errors {
		margin-bottom: 1rem;
		li {
			color: $error-color;
		}
	}
	.btn {
		padding: 1rem 3rem 1rem;
		cursor: pointer;
		box-shadow: 0 0.5rem 0 0 $slate;
	}
	p, .body & p:last-child:not(.btn) {
		margin-bottom: 3rem;
	}
}

// not really worth putting this in it's own file!
.JS #giftaid-statement {
	display: none;
}