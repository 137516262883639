.main__molehillMtn {
    .page-header {
        * {
            color: $white;
        }
        &__text {
            background-color: $navy;
            &__heading {
                @include big-heading-font;
		        @include avenirRegular;
            }
            &__section {
                @include avenirBold;
            }
        }
    }
    .leading-quote {
        background-color: $lightBlue;
    }
    .blockquote {
		&__text {
			@include crossHeading;
			@include avenirRegular;
            &:before {
                background-image: url("data:image/svg+xml;charset=utf8,%3Csvg width='34' height='22' viewBox='0 0 34 22' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.166 6.668C6.201 6.668 4.474 7.386 3.205 8.58C4.808 3.689 8.888 2.497 12.444 2.497C12.858 2.497 13.193 2.162 13.193 1.748C13.193 1.335 12.857 1 12.444 1C9.003 1 1 2.219 1 13.513C1 13.535 1.011 13.553 1.012 13.574C1.009 13.661 1 13.746 1 13.834C1 17.919 4.081 21 8.166 21C12.251 21 15.332 17.919 15.332 13.834C15.332 9.749 12.251 6.668 8.166 6.668Z' fill='%239376BB'/%3E%3Cpath d='M24.834 6.668C22.869 6.668 21.142 7.386 19.873 8.58C21.476 3.689 25.556 2.497 29.112 2.497C29.526 2.497 29.861 2.162 29.861 1.748C29.861 1.335 29.526 1 29.112 1C25.671 1 17.668 2.219 17.668 13.513C17.668 13.535 17.679 13.553 17.68 13.574C17.677 13.661 17.668 13.746 17.668 13.834C17.668 17.919 20.749 21 24.834 21C28.919 21 32 17.919 32 13.834C32 9.749 28.919 6.668 24.834 6.668Z' fill='%239376BB'/%3E%3C/svg%3E");
                background-position: right calc(50% + 3px);
                background-repeat: no-repeat;
            }
		}
		&__source {
			@include small-body-font;
			@include avenirBold;
		}
	}
	.stats {
		&__stat {
			&__figure {
				@include avenirRegular;
				color: $purple;
			}
			&__text {
				@include avenirBold;
			}
		}
	}
	.hr {
		border-color: $blueCyan;
	}
    .cta {
		background-color: $blueElectric;
		&__text {
            @include avenirBold;
			color: $navy;
		}
        .btn {
			color: $white;
			background-color: $navy;
			&:hover {
				background-color: darken($navy, 10%);
			}
		}
	}
    .multi-cta {
        background-color: $blueElectric;
        margin-bottom: 0;
        .btn {
            margin: 2.5rem 2.25rem;
            &--img {
                padding: 0;
                margin-top: 2.75rem;
                margin-left: 1.5rem;
                margin-right: 1.5rem;
                border-radius: 0;
                background-color: transparent;
                img {
                    display: block;
                }
            }
        }
    }
    .accordion {
        h1 {
            @include avenirRegular;
        }
        &__heading {
            color: $navy;
            border-top: 1px solid rgba($navy, .6);
            background-image: url("data:image/svg+xml,%3Csvg width='24' height='3' viewBox='0 0 24 3' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.81899e-10 0.00195312L0 2.00195L24 2.00195L24 0.00195313L1.81899e-10 0.00195312Z' fill='%239376BB'/%3E%3C/svg%3E%0A");
            background-position: right calc(50% + 3px);
            background-repeat: no-repeat;
            &__plus {
                path {
                    fill: $purple;
                }
            }
        }
        &:after {
            border-top: 1px solid rgba($navy, .6);
        }
    }
    .blue-standout {
        background-color: $navy;
    }
    .links {
        &--1-col {
            border-top: 1px solid $blueCyan;
            background-color: $lightBlue;
        }
        &__link {
            p {
				@include body-font;
                @include avenirRegular;
			}
            &__heading {
                @include crossHeading;
                @include avenirBold;
                &:before {
                    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg width='34' height='22' viewBox='0 0 34 22' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.166 6.668C6.201 6.668 4.474 7.386 3.205 8.58C4.808 3.689 8.888 2.497 12.444 2.497C12.858 2.497 13.193 2.162 13.193 1.748C13.193 1.335 12.857 1 12.444 1C9.003 1 1 2.219 1 13.513C1 13.535 1.011 13.553 1.012 13.574C1.009 13.661 1 13.746 1 13.834C1 17.919 4.081 21 8.166 21C12.251 21 15.332 17.919 15.332 13.834C15.332 9.749 12.251 6.668 8.166 6.668ZM8.166 19.502C4.935 19.502 2.498 17.065 2.498 13.834C2.498 10.603 4.935 8.166 8.166 8.166C11.397 8.166 13.834 10.603 13.834 13.834C13.834 17.066 11.397 19.502 8.166 19.502Z' fill='%239376BB'/%3E%3Cpath d='M24.834 6.668C22.869 6.668 21.142 7.386 19.873 8.58C21.476 3.689 25.556 2.497 29.112 2.497C29.526 2.497 29.861 2.162 29.861 1.748C29.861 1.335 29.526 1 29.112 1C25.671 1 17.668 2.219 17.668 13.513C17.668 13.535 17.679 13.553 17.68 13.574C17.677 13.661 17.668 13.746 17.668 13.834C17.668 17.919 20.749 21 24.834 21C28.919 21 32 17.919 32 13.834C32 9.749 28.919 6.668 24.834 6.668ZM24.834 19.502C21.603 19.502 19.166 17.065 19.166 13.834C19.166 10.603 21.603 8.166 24.834 8.166C28.065 8.166 30.502 10.603 30.502 13.834C30.502 17.066 28.066 19.502 24.834 19.502Z' fill='%239376BB'/%3E%3C/svg%3E");
                    background-position: right calc(50% + 3px);
                    background-repeat: no-repeat;
                }
                &:after {
                    content: none;
                }
                a {
                    @include navyLink;
                }
            }
        }
    }
    .biography {
        border-top: 1px solid $blueCyan;
        background-color: $lightBlue;
    }
    .donate-form {
        &__panel {
            background-color: $lightBlue;
            &__heading {
                @include avenirBold;
            }
        }
        &__radio-group {
            &__label {
                @include avenirBold;
                background-color: $white;
                color: $navy;
                border: 1px solid $navy;
            }
        }
        &__custom-amount {
            border: 1px solid $navy;
        }
    }
    :checked + .donate-form__radio-group__label {
        background-color: $navy;
        color: $white;
        box-shadow: none;
        &.frequency {
            &:before {
                border-color: $navy transparent transparent transparent;
            }
        }
    }
}