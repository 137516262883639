.donate {
	position: relative;
	&--yellow {
		background-color: $blue;
	}
	&__bg-image {
		background-size: cover;
		height: 28rem;
		@include breakpoint(smallish) {
			height: 39rem;
		}
	}
	@include breakpoint(medium) {
		min-height: 90rem;
		&__bg-image {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			height: auto;
		}
	}
}
.donate-introduction {
	padding-top: 6rem;
  padding-bottom: 8rem;
	background-color: $very-light-blue;
	& :last-child:not(.btn) {
		margin-bottom: 0;
	}
}
