.mini-content-toggle {
	color: $dark-blue;
	cursor: pointer;
	display: inline-block;
	&__arrow {
		display: inline-block;
		float: right;
		width: .9em; // ems here are so .mini-content-toggle could use any sized font
		height: 1.3em;
		margin-left: .3em;
		position: relative;
		transition: transform .4s;
		path {
			fill: $dark-blue;
		}
	}
	&--active .mini-content-toggle__arrow {
		transform: rotate(180deg);
	}
}