.nav {
	&__search-btn {
		display: none;
	}
	&__search {
		background: $slate-10-rgb;
		&__input {
			@include nav-font;
			display: block;
			width: 100%;
			height: 6rem;
			border: none;
			padding: 1.25rem 0 .75rem 7rem;
			margin: 0;
			@include box;
			// search-white.svg
			background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDIzLjAuNiwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHdpZHRoPSIyMHB4IiBoZWlnaHQ9IjI0cHgiIHZpZXdCb3g9IjAgMCAyMCAyNCIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMjAgMjQ7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4KPHN0eWxlIHR5cGU9InRleHQvY3NzIj4KCS5zdDB7ZmlsbDojMDA4Mjc5O30KPC9zdHlsZT4KPGc+Cgk8cGF0aCBjbGFzcz0ic3QwIiBkPSJNMjAsMjAuNTg2bC02LjA0OS02LjA0OWMxLjAxNy0xLjMxNiwxLjYyOS0yLjk2LDEuNjI5LTQuNzQ5QzE1LjU4LDUuNDk0LDEyLjA4NSwyLDcuNzg5LDIKCQlDMy40OTQsMiwwLDUuNDk0LDAsOS43ODljMCw0LjI5NiwzLjQ5NCw3Ljc5LDcuNzg5LDcuNzljMS43ODgsMCwzLjQzMi0wLjYxMSw0Ljc0OC0xLjYyOEwxOC41ODYsMjJMMjAsMjAuNTg2eiBNMiw5Ljc4OQoJCUMyLDYuNTk3LDQuNTk3LDQsNy43ODksNGMzLjE5MywwLDUuNzkxLDIuNTk3LDUuNzkxLDUuNzg5YzAsMy4xOTMtMi41OTgsNS43OS01Ljc5MSw1Ljc5QzQuNTk3LDE1LjU3OSwyLDEyLjk4MiwyLDkuNzg5eiIvPgo8L2c+Cjwvc3ZnPgo=') 24px 1.5rem no-repeat;
			color: $new_text_color;
			&::placeholder {
				color: $new_text_color;
			}
		}
		&__submit {
			display: none;
		}
	}
	&__sections {
		background: $new_text_color;
		padding-top: 2rem;
		&__donate {
			@extend %screen-margins-with-gutters;
			padding-top: 2rem;
			padding-bottom: 3rem;
			.btn {
				background-color: $white;
				color: $new_text_color;
				box-shadow: none;
			}
		}
	}
	&__section {
		@extend %gutters;
		@include nav-font;
		display: block; // contact is a link, so needs this
		margin-left: $screen-margin-small;
		margin-right: $screen-margin-small;
		color: #fff;
		padding-top: 1.25rem;
		padding-bottom: .75rem;
		position: relative;
		cursor: pointer;
		&:not(:first-child):before {
			content: '';
			display: block;
			position: absolute;
			top: 0;
			left: $gutter-half-width;
			right: $gutter-half-width;
			border-top: 1px solid $slate-30-rgb;
		}
		&:hover, &--selected {
			color: $white;
			a {
				color: $turquoise !important;
			}
		}
		@include breakpoint(smallish) {
			margin-left: $screen-margin-smallish;
			margin-right: $screen-margin-smallish;
		}
		&__arrow {
			position: absolute;
			top: 1rem;
			right: $gutter-half-width;
			transition: transform .4s;
		}
		a {
			@include nav-font;
			color: #fff;
		}
		a:hover {
			color: $turquoise;
		}
	}
	.section-wrap {
		padding: 3rem 0;
	}
	&__column {
		@extend %screen-margins-with-gutters;
	}
	&__list {
		&__item {
			@include nav-font;
			color: #fff;
			margin-bottom: 1rem;
			&__link {
				color: #fff;
				&--selected, &:hover {
					color: $white;
				}
			}
			a {
				@include link-white;
			}
		}
		&__heading {
			@include small-text-font;
			font-family: 'acumin-pro', sans-serif;
			font-weight: 700;
			color: $slate-30-rgb;
			margin: 3rem 0 1rem;
		}
	}
	@include breakpoint(smallish) {
		&__search {
			&__input {
				background-position: $screen-margin-smallish-with-gutter 1.5rem;
				padding-left: 10rem;
			}
		}
	}
	/******************************************************************************************
	JS MOBILE NAV
	******************************************************************************************/
	.JS & {
		position: fixed;
		top: $header-height-small;
		left: 0;
		right: 0;
		bottom: 0;
		margin-left: 100%;
		margin-right: -100%;
		&__sections {
			position: absolute;
			top: 6rem;
			left: 0;
			right: 0;
			bottom: 0;
			overflow: auto;
			-webkit-overflow-scrolling: touch; // smooth 'momentum' scrolling on iOS
		}
		&__panel {
			display: none;
		}
		&__section--is-active {
			.nav__section__arrow {
				transform: rotate(-180deg);
			}
		}
	}
	/******************************************************************************************
	2 COLUMN LISTS
	******************************************************************************************/
	@include breakpoint(medium) {
		&__section {
			clear: both;
		}
		&__column {
			width: 50%;
			float: left;
			@include box;
		}
		&__list__heading:first-child {
			margin-top: 0;
			border-top: none;
			padding-top: 0;
		}
	}
	/******************************************************************************************
	DESKTOP NAV
	******************************************************************************************/
	@include breakpoint($nav-breakpoint) {
		.JS & {
			margin-left: 0 !important; // !important ensures desktop nav is visible if mobile nav was opened and closed
			margin-right: 0 !important;
			padding: 0 $gutter-half-width;
			position: relative;
			float: right;
			width: auto;
			text-align: right;
			min-width: 112.5rem;
			@include box;
			top: 0;
			margin-top: 3.25rem;
			transform: none;
			transition: none;
			.section-wrap {
				padding: 0;
			}
			&__search-btn {
				display: block;
				position: absolute;
				right: $screen-margin-smallish;
				top: 2rem;
				z-index: 2; // pull infront of search box
				path, circle {
					stroke: $green-strong;
				}
				&:hover {
					cursor: pointer;
					path, circle {
						stroke: $slate;
					}
					path.close {
						stroke: $slate;
					}
				}
			}
			&__search {
				position: absolute;
				top: -3.25rem;
				left: $gutter-half-width;
				right: 0;
				height: $header-height-medium;
				padding-right: 40px + $gutter-half-width;
				z-index: 1; // pull infront of section links
				opacity: 0;
				visibility: hidden;
				transition: opacity .2s, visibility .2s;
				&--is-open {
					opacity: 1;
					visibility: visible;
				}
				&__input {
					height: $header-height-medium;
					padding-top: 4rem;
					padding-bottom: 3.25rem;
					background-position: 3rem 4.5rem;
					padding-left: 7.5rem;
					&:focus {
						outline: none;
					}
				}
			}
			&__sections {
				position: relative;
				display: inline-block;
				top: 0;
				background: none;
				padding: 0;
				overflow: visible;
				@include clearfix;
				&__donate {
					margin: -1rem 5rem 0 3rem;
					padding: 0;
					float: right;
					padding-bottom: 0;
					.btn {
						padding-left: 2rem;
						padding-right: 2.125rem;
						background-color: $green-strong;
						box-shadow: 0 .5rem 0 0 $slate;
						color: $white;
						&:hover {
							background-color: $slate;
							box-shadow: 0 .5rem 0 0 $new_text_color;
						}
					}
				}
			}
			&__section {
				margin: 1rem 0 0;
				color: $green-strong;
				float: left;
				clear: none;
				padding: 0;
				&:before {
					content: none;
				}
				&:not(:first-child) {
					padding-left: 1rem;
					//border-left: 1px solid $slate-55-rgb;
					margin-left: 1.5rem;
				}
				&.has-dropdown {
					padding-right: 2rem;
				}
				&:hover, &--selected {
					color: $slate;
				}
				&--is-selected {
					color: $turquoise;

					a {
						color: $turquoise;
					}
				}
				&--is-active {
					color: $slate !important;
					position: relative;
					&:after {
						content: '';
						display: block;
						width: 0;
						height: 0;
						position: absolute;
						bottom: -4rem;
						left: 50%;
						margin-left: -1.5rem; // half of width of right padding on .nav__section
						border-left: 1.5rem solid transparent;
						border-right: 1.5rem solid transparent;
						border-bottom: 1.5rem solid $new_text_color;
					}
					.nav__section__arrow {
						transform: scale(.7) rotate(-180deg);
					}
				}

				&__single:after {
					display: none;
				}
				&__arrow {
					//display: none;
					right: -.75rem;
					top: .125rem;
					transform: scale(.7);
					path {
						fill: $green;
					}
				}
			}
			&__panel {
				position: fixed;
				top: $header-height-medium;
				left: 0;
				right: 0;
				bottom: auto;
				background: $new_text_color;
				box-shadow: 0px 10px 20px 0 rgba(0,0,0,.35);
				// max-height: 80vh;
				max-height: calc(100vh - #{$header-height-medium});
				overflow: auto;
			}
			&__column {
				padding: 4rem $gutter-half-width 5rem;
				width: 33.333333%;
				text-align: left;
				&:first-child {
					margin-left: calc(100% - 900px);
				}
			}
		}
	}
}
