.body {
	@extend %section-margins;
	clear: both;
	@include clearfix;
	& :first-child {
		margin-top: 0;
	}
	& :last-child:not(.btn) { // we don't cancel margins on btns as they effect how they click in
		margin-bottom: 0;
	}
	&--yellow {
		border-top: 1px solid $slate-55-rgb;
		background-color: $slate-05;
		.sidebar-card__content {
			border-top: 1rem solid $slate-55-rgb;
			background-color: $white;
		}
	}
	&__heading {
		@include big-heading-font;
		margin-bottom: 5rem;
	}
}

.main__employerToolkit {
	.body + .body {
		padding-top: 0;
		margin-top: -5rem;
	}
}

.main__signsAndSymptomsV2 {
	.body-content {
		& > *:not(.cta):nth-child(even) {
			background-color: $slate-05;
		}
	}
}