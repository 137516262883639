.donate-form {
	&__panel {
		@extend %negative-screen-margins;
		@extend %screen-margins-with-gutters;
		@extend %section-margins;
		background-color: $white;
		&__heading {
			@include medium-heading-font;
			margin-bottom: 3rem;
		}
		&__p {
			margin-bottom: 3rem;
		}
		&__back {
			margin: 4rem 0 0 !important;
		}
	}
	&__radio-group {
		@include clearfix;
		margin-bottom: 2rem;
		// hide SINGLE payment options until needed
		&--single {
			.JS & {
				display: none;
				&.show {
					display: block;
				}
			}
		}
		&__radio {
			display: none;
		}
		&.frequency {
			margin-bottom: 2.5rem;
		}
		&__label {
			display: block;
			float: left;
			@include body-bold-font;
			text-align: center;
			background-color: $white;
			color: $green-strong;
			border: 1px solid $green-strong;
			padding: 1rem 0;
			box-shadow: inset 0 4px 0 0 $slate-10;
			cursor: pointer;
			width: 50%;
			.donate-form__radio-group--planAmount & {
				width: 33.333333%;
			}
			&:not(:last-of-type) {
				border-width: 1px 0 1px 1px;
			}
			&:first-of-type {
				border-radius: 4px 0 0 4px;
			}
			&:last-of-type {
				border-radius: 0 4px 4px 0;
			}
			@include box;
			:checked + & {
				background-color: $new_text_color;
				color: $white;
				box-shadow: none;
				border: 1px solid $new_text_color;
				&.frequency {
					position: relative;
					&.only-option {
						width: 100%;
						border-radius: 4px;
					}
					&:before {
						content: '';
						position: absolute;
						width: 0;
						height: 0;
						border-style: solid;
						border-width: 20px 20px 0 20px;
						border-color: $new_text_color transparent transparent transparent;
						left: 50%;
						margin-left: -20px;
						bottom: -20px;
					}
				}
			}
		}
		&__prompt {
			padding-top: 2rem;
			clear: both;
			float: left;
			&:not(:first-of-type) {
				display: none;
			}
		}
	}
	&__label {
		@include body-bold-font;
		display: block;
		margin-bottom: 1rem;
	}
	&__custom-amount {
		border: 1px solid $green-strong;
		display: block;
		width: 100%;
		padding: 1rem 1.5rem;
		@include box;
		@include body-font;
		margin-bottom: 4rem;
		box-shadow: inset 0 4px 0 0 rgba(0, 0, 0, .08);
		background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' height='50px' width='85px'><text x='10' y='33' fill='gray' font-size='22' font-family='arial'>£</text></svg>");
		background-repeat: no-repeat;
		padding-left: 30px;
	}
	&__giftaid {
		&__heading {
			@include body-bold-font;
			margin: 1rem 0 1rem;
		}
		&__checkbox {
			float: left;
			font-size: auto;
			margin-top: 10px;
		}
		&__label {
			@include body-font;
			margin-bottom: 2rem;
			display: block;
			&__text {
				display: block;
				margin-left: 3rem;
			}
		}
		&__about {
			@include small-text-font;
			margin-bottom: 3rem;
		}
	}
	.stripe-button-el {
		padding: 0;
		background: none !important;
		box-shadow: none;
		overflow: visible;
		&:focus {
			outline: none;
		}
		span {
			@extend %btn;
			margin: 1rem 0 .5rem;
			border: 0;
			text-shadow: none;
			font-family: 'Source Sans Pro', sans-serif;
		}
		&:active span {
			background: $dark-blue !important;
		}
	}
	@include breakpoint(medium) {
		position: relative; // pull panel in front of bg image
		width: 66.666666%;
		margin-left: 33.333333%;
		padding: 0 $gutter-half-width;
		@include box;
		&__panel {
			margin: 8rem 0;
			padding: 3rem 3rem 8rem;
		}
	}
	@include breakpoint(large) {
		width: 50%;
	}
}
.donation__confirmation {
	@include body-font;
	margin-bottom: 3rem;
	margin-top: 3rem;
	h3 {
		@include medium-heading-font;
	}
	.donation__details {
		@include medium-heading-font;
		span {
			display: inline-block;
			color: $turquoise;
		}
	}
}
.body {
	.donate-form__panel {
		background-color: $slate-05;
	}
}
