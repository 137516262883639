html {
	font-size: 8px; // Safati seems to interpret 50% as 9px not 8px!!!
	-webkit-text-size-adjust: none; // prevent iOS from resizing small text
}

/******************************************************************************************
FONT MIXINS
******************************************************************************************/
/*
 * The Typekit service used to deliver this font or fonts for use on websites
 * is provided by Adobe and is subject to these Terms of Use
 * http://www.adobe.com/products/eulas/tou_typekit. For font license
 * information, see the list below.
 *
 * acumin-pro:
 *   - http://typekit.com/eulas/00000000000000003b9acafc
 *   - http://typekit.com/eulas/00000000000000003b9acafd
 *   - http://typekit.com/eulas/00000000000000003b9acaf7
 *   - http://typekit.com/eulas/00000000000000003b9acaf4
 *   - http://typekit.com/eulas/00000000000000003b9acaf5
 *   - http://typekit.com/eulas/00000000000000003b9acaf6
 * dolly-new:
 *   - http://typekit.com/eulas/00000000000000003b9b3f11
 *   - http://typekit.com/eulas/00000000000000003b9b3f13
 *
 * © 2009-2020 Adobe Systems Incorporated. All Rights Reserved.
 */
/*{"last_published":"2020-08-07 12:29:20 UTC"}*/

@import url("https://p.typekit.net/p.css?s=1&k=mxy8alc&ht=tk&f=26053.26054.26056.26058.26059.26062.40804.40806&a=35151801&app=typekit&e=css");

@font-face {
font-family:"acumin-pro";
src:url("https://use.typekit.net/af/6d4bb2/00000000000000003b9acafc/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff2"),url("https://use.typekit.net/af/6d4bb2/00000000000000003b9acafc/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff"),url("https://use.typekit.net/af/6d4bb2/00000000000000003b9acafc/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("opentype");
font-display:auto;font-style:normal;font-weight:700;
}

@font-face {
font-family:"acumin-pro";
src:url("https://use.typekit.net/af/6ce26b/00000000000000003b9acafd/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("woff2"),url("https://use.typekit.net/af/6ce26b/00000000000000003b9acafd/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("woff"),url("https://use.typekit.net/af/6ce26b/00000000000000003b9acafd/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("opentype");
font-display:auto;font-style:italic;font-weight:700;
}

@font-face {
font-family:"acumin-pro";
src:url("https://use.typekit.net/af/aa5b59/00000000000000003b9acaf7/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("woff2"),url("https://use.typekit.net/af/aa5b59/00000000000000003b9acaf7/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("woff"),url("https://use.typekit.net/af/aa5b59/00000000000000003b9acaf7/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("opentype");
font-display:auto;font-style:italic;font-weight:400;
}

@font-face {
font-family:"acumin-pro";
src:url("https://use.typekit.net/af/a2c82e/00000000000000003b9acaf4/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3") format("woff2"),url("https://use.typekit.net/af/a2c82e/00000000000000003b9acaf4/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3") format("woff"),url("https://use.typekit.net/af/a2c82e/00000000000000003b9acaf4/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3") format("opentype");
font-display:auto;font-style:normal;font-weight:300;
}

@font-face {
font-family:"acumin-pro";
src:url("https://use.typekit.net/af/51b548/00000000000000003b9acaf5/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i3&v=3") format("woff2"),url("https://use.typekit.net/af/51b548/00000000000000003b9acaf5/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i3&v=3") format("woff"),url("https://use.typekit.net/af/51b548/00000000000000003b9acaf5/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i3&v=3") format("opentype");
font-display:auto;font-style:italic;font-weight:300;
}

@font-face {
font-family:"acumin-pro";
src:url("https://use.typekit.net/af/46da36/00000000000000003b9acaf6/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff2"),url("https://use.typekit.net/af/46da36/00000000000000003b9acaf6/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff"),url("https://use.typekit.net/af/46da36/00000000000000003b9acaf6/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("opentype");
font-display:auto;font-style:normal;font-weight:400;
}

@font-face {
font-family:"dolly-new";
src:url("https://use.typekit.net/af/b03447/00000000000000003b9b3f11/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("woff2"),url("https://use.typekit.net/af/b03447/00000000000000003b9b3f11/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("woff"),url("https://use.typekit.net/af/b03447/00000000000000003b9b3f11/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("opentype");
font-display:auto;font-style:italic;font-weight:400;
}

@font-face {
font-family:"dolly-new";
src:url("https://use.typekit.net/af/724586/00000000000000003b9b3f13/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff2"),url("https://use.typekit.net/af/724586/00000000000000003b9b3f13/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff"),url("https://use.typekit.net/af/724586/00000000000000003b9b3f13/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("opentype");
font-display:auto;font-style:normal;font-weight:400;
}

@font-face {
	font-family:"dolly-new";
	src:url("https://use.typekit.net/af/724586/00000000000000003b9b3f13/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff2"),url("https://use.typekit.net/af/724586/00000000000000003b9b3f13/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff"),url("https://use.typekit.net/af/724586/00000000000000003b9b3f13/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("opentype");
	font-display:auto;
	font-style:normal;
	font-weight:400;
}

html {
	font-family: 'acumin-pro', sans-serif;
	font-weight: normal;
	-webkit-font-smoothing: antialiased; // less blurry fonts for chrome
	-moz-osx-font-smoothing: grayscale; // less blurry fonts for FF
}



@mixin body-font {
	// 20px/28px
	font-size: 2.5rem;
	line-height: 1.4;
	font-weight: 400;
	@include breakpoint(medium) {
		// 22px/32px
		font-size: 2.75rem;
		line-height: 1.454546;
	}
}
@mixin body-bold-font {
	font-family: 'acumin-pro', sans-serif;
	// 20px/28px
	font-size: 2.5rem;
	line-height: 1.4;
	font-weight: 700;
	@include breakpoint(medium) {
		// 22px/32px
		font-size: 2.75rem;
		line-height: 1.454546;
	}
}
@mixin medium-text-font {
	// 24px/32px
	font-size: 2.625rem;
	line-height: 1.5;
	font-weight: 400;
	@include breakpoint(medium) {
		// 28px/40px
		font-size: 3.125rem;
		line-height: 1.428571;
	}
}
@mixin medium-heading-font {
	font-family: 'dolly-new', serif;
	// 24px/32px
	font-size: 3rem;
	line-height: 1.5;
	font-weight: 400;
	@include breakpoint(medium) {
		// 28px/40px
		font-size: 3.5rem;
		line-height: 1.428571;
	}
}
@mixin medium-body-font {
	font-family: 'acumin-pro', sans-serif;
	// 24px/32px
	font-size: 3rem;
	line-height: 1.5;
	font-weight: 700;
	@include breakpoint(medium) {
		// 28px/36px
		font-size: 3.5rem;
		line-height: 1.3;
	}
}
@mixin big-body-font {
	// 32px/40px
	font-size: 4rem;
	line-height: 1.25;
	font-weight: 700;
	@include breakpoint(smallish) {
		// 40px/48px
		font-size: 5rem;
		line-height: 1.2;
	}
}
@mixin big-heading-font {
	font-family: 'dolly-new', serif;
	// 32px/40px
	font-size: 4rem;
	line-height: 1.25;
	font-weight: 400;
	@include breakpoint(smallish) {
		// 40px/48px
		font-size: 5rem;
		line-height: 1.2;
	}
}
@mixin big-stat-font {
	font-family: 'acumin-pro', sans-serif;
	// 60px/56px
	font-size: 7.5rem;
	line-height: 0.933334;
	font-weight: 300;
}
@mixin small-text-font {
	// 18px/24px
	font-size: 2.25rem;
	line-height: 1.33334;
	font-weight: 400;
}
@mixin small-body-font {
	// 18px/24px
	font-size: 2.5rem;
	line-height: 1.33334;
}
@mixin small-heading-font {
	// 18px/24px
	font-size: 2.25rem;
	line-height: 1.33334;
	font-weight: 700;
}
@mixin button-font {
	// 22px/32px
	font-size: 2.75rem;
	line-height: 1.454545;
	font-weight: 400;
}
@mixin nav-font {
	// 22px/32px
	font-size: 2.75rem;
	line-height: 1.454546;
	font-weight: 400;
}

@mixin link {
	color: $green-strong;
	background-image: linear-gradient($green-strong 50%, rgba(0,0,0,0) 50%);
	background-repeat: repeat-x;
	background-size: 2px 2px;
	background-position: 0 98.75%;
}
@mixin link-white {
	color: $white;
	background-image: linear-gradient($white 50%, rgba(0,0,0,0) 50%);
	background-repeat: repeat-x;
	background-size: 2px 2px;
	background-position: 0 98.75%;
}
@mixin no-link {
	background: none;
}

/******************************************************************************************
TEXT STYLES
******************************************************************************************/

// fallback for IE8
@if $old-ie {
	* {
		font-size: 16px;
		line-height: 24px;
	}
}

* {
	color: $new_text_color;
}

p, li {
	@include body-font;
	&.small {
		@include small-body-font;
	}
}
ul, ol {
	@include body-font;
	margin-bottom: 0; // ULs just get bottom margin if they're in a .wysiwyg block
}
li {
	margin-bottom: 0;
	list-style: none;
}
strong {
	font-family: 'acumin-pro', sans-serif;
	font-weight: 700;
}
em {
	font-style: italic;
}
a {
	text-decoration: none;
	font-weight: inherit;
	p &, li &, span & {
		@include link;
	}
	* {
		color: $green-strong;
	}
}
a:focus, a:active {
	outline: none;
}
img {
	max-width: 100%;
}
sup, sub {
	vertical-align: baseline;
	position: relative;
	top: -0.2em;
	font-size: .6em;
	line-height: 1em;
}
sub {
	top: 0.2em;
}
// our reset doesn't cover these!
figure, figcaption {
	margin: 0;
	padding: 0;
}

/******************************************************************************************
WYSIWYG STYLES
******************************************************************************************/

.wysiwyg {
	p, ul, ol, div {
		margin-bottom: 3rem;
	}
	div {
		@include body-font;
	}
	h2:not(.body__heading) {
		@include medium-body-font;
		margin-bottom: 3rem;
		margin-top: 5rem;
		&:first-child {
			margin-top: 0;
		}
	}
	.standfirst {
		@include medium-text-font;
	}
	ul {
		margin-bottom: 3rem;
		li {
			padding-left: 2.5rem;
			position: relative;
			&:before {
				content: '';
				position: absolute;
				top: 1.25rem;
				left: 0;
				width: 1rem;
				height: 1rem;
				background: $slate-55-rgb;
				border-radius: 50%;
				margin: 0 .6rem 0 .2rem;
			}
		}
	}
	ol {
		margin-bottom: 3rem;
		li {
			min-height: 8rem;
			border-top: 1px solid $slate-55-rgb;
			padding: 1.375rem 0 1rem 9rem;
			&:first-child {
				counter-reset: index;
				border: none;
			}
			&:before {
				content: counter(index);
				counter-increment: index;
				width: 9rem;
				height: 7rem;
				position: absolute;
				margin-left: -9rem;
				display: block;
				// vertical-align: text-top;
				color: $slate;
				font-size: 7.5rem;
				line-height: 7rem;
				font-family: "acumin-pro", serif;
				font-weight: 300;
				font-feature-settings: "tnum";
			}
		}
	}
}

/******************************************************************************************
FORMS
******************************************************************************************/

input[type="text"], input[type="email"], textarea, select, button {
	-webkit-appearance: none;
	border-radius: 0;
}
.input, .textarea, .btn, .select {
	border: none;
}
.select {
	display: inline-block;
	// remove select arrows
	-moz-appearance: none; text-indent: 1px; text-overflow: ''; // removes arrow from FF - see http://css-tricks.com/almanac/properties/a/appearance/
	// add our own arrow
	&:after {
		content: '';
		display: inline-block;
		width: 0;
		border-style: solid;
		border-width: 1rem .6rem 0;
		border-color: #000 transparent transparent;
		margin-left: .9rem;
		position: relative;
		top: .05rem;
	}
}
// remove select arrows on IE
select::-ms-expand {
		display: none;
}

/******************************************************************************************
BLOCKQUOTE STYLE
******************************************************************************************/

%blockquote-text {
	@include medium-heading-font;
	color: $new_text_color;
	&:before {
		content: '';
		display: block;
		width: 33px;
		height: 26px;
		display: inline-block;
		position: relative;
	}
	// positioning here is based on Hugo Silva's answer at https://stackoverflow.com/questions/16100956/prevent-after-element-from-wrapping-to-next-line – it relies on no white space at end of enclosing tag
	&:before {
		top: 1px;
		margin-right: 7px;
		background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDIzLjAuNiwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHdpZHRoPSIzM3B4IiBoZWlnaHQ9IjI2cHgiIHZpZXdCb3g9IjAgMCAzMyAyNiIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMzMgMjY7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4KPHN0eWxlIHR5cGU9InRleHQvY3NzIj4KCS5zdDB7ZmlsbDojMDBBNTkwO30KCS5zdDF7ZmlsbDojRUI2NDhEO30KCS5zdDJ7ZmlsbDojMDA0NDRGO30KPC9zdHlsZT4KPGc+Cgk8cGF0aCBjbGFzcz0ic3QwIiBkPSJNMTIuNzIxLDAuMjExTDIuNTI5LDEwLjQwM2wwLjAwNCwwLjAwM0MwLjk2NSwxMi4wNCwwLDE0LjI1NiwwLDE2LjY5OWMwLDUuMDIsNC4wNjksOS4wOTEsOS4wOSw5LjA5MQoJCWMzLjA1NywwLDUuNzYyLTEuNTA5LDcuNDEtMy44MjNjLTEuMDU4LTEuNDg2LTEuNjgxLTMuMzA0LTEuNjgxLTUuMjY4YzAtMS45NjMsMC42MjQtMy43OCwxLjY4Mi01LjI2NgoJCWMtMS42NDctMi4zMTUtNC4zNTMtMy44MjQtNy40MTEtMy44MjRjLTAuMTg3LDAtMC4zNzIsMC4wMDctMC41NTcsMC4wMThsNS44MDItNS44MDJMMTIuNzIxLDAuMjExeiIvPgoJPHBhdGggY2xhc3M9InN0MSIgZD0iTTI3LjU0LDAuMjExTDE3LjM0OCwxMC40MDNsMC4wMDQsMC4wMDNjLTAuMzA4LDAuMzIxLTAuNTkyLDAuNjY0LTAuODUxLDEuMDI3CgkJYzEuMDU4LDEuNDg2LDEuNjgsMy4zMDMsMS42OCw1LjI2NmMwLDEuOTY0LTAuNjIzLDMuNzgyLTEuNjgxLDUuMjY4YzEuNjQ3LDIuMzE0LDQuMzUzLDMuODIzLDcuNDEsMy44MjMKCQljNS4wMiwwLDkuMDkxLTQuMDcsOS4wOTEtOS4wOTFjMC01LjAyMS00LjA3MS05LjA5LTkuMDkxLTkuMDljLTAuMTg3LDAtMC4zNzIsMC4wMDctMC41NTcsMC4wMThsNS44MDItNS44MDJMMjcuNTQsMC4yMTF6Ii8+Cgk8cGF0aCBjbGFzcz0ic3QyIiBkPSJNMTYuNTAxLDExLjQzM2MtMS4wNTksMS40ODYtMS42ODIsMy4zMDItMS42ODIsNS4yNjZzMC42MjIsMy43ODEsMS42ODEsNS4yNjgKCQljMS4wNTgtMS40ODcsMS42OC0zLjMwNCwxLjY4LTUuMjY4QzE4LjE4LDE0LjczNiwxNy41NTksMTIuOTE5LDE2LjUwMSwxMS40MzMiLz4KPC9nPgo8L3N2Zz4K'); // l-quote.svg
	}
	// &:after {
	// 	margin-left: 7px;
	// 	background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDIzLjAuNiwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHdpZHRoPSIzM3B4IiBoZWlnaHQ9IjI2cHgiIHZpZXdCb3g9IjAgMCAzMyAyNiIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMzMgMjY7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4KPHN0eWxlIHR5cGU9InRleHQvY3NzIj4KCS5zdDB7ZmlsbDojMDBBNTkwO30KCS5zdDF7ZmlsbDojRUI2NDhEO30KCS5zdDJ7ZmlsbDojMDA0NDRGO30KPC9zdHlsZT4KPGc+Cgk8cGF0aCBjbGFzcz0ic3QwIiBkPSJNMjAuMjc5LDI1Ljc4OWwxMC4xOTItMTAuMTkybC0wLjAwNC0wLjAwM0MzMi4wMzUsMTMuOTYsMzMsMTEuNzQ0LDMzLDkuMzAxYzAtNS4wMi00LjA2OS05LjA5MS05LjA5LTkuMDkxCgkJYy0zLjA1NywwLTUuNzYyLDEuNTA5LTcuNDEsMy44MjNjMS4wNTgsMS40ODYsMS42ODEsMy4zMDQsMS42ODEsNS4yNjhjMCwxLjk2My0wLjYyNCwzLjc4LTEuNjgyLDUuMjY2CgkJYzEuNjQ3LDIuMzE1LDQuMzUzLDMuODI0LDcuNDExLDMuODI0YzAuMTg3LDAsMC4zNzItMC4wMDcsMC41NTctMC4wMThsLTUuODAyLDUuODAyTDIwLjI3OSwyNS43ODl6Ii8+Cgk8cGF0aCBjbGFzcz0ic3QxIiBkPSJNNS40NiwyNS43ODlsMTAuMTkyLTEwLjE5MmwtMC4wMDQtMC4wMDNjMC4zMDgtMC4zMjEsMC41OTItMC42NjQsMC44NTEtMS4wMjcKCQljLTEuMDU4LTEuNDg2LTEuNjgtMy4zMDMtMS42OC01LjI2NmMwLTEuOTY0LDAuNjIzLTMuNzgyLDEuNjgxLTUuMjY4Yy0xLjY0Ny0yLjMxNC00LjM1My0zLjgyMy03LjQxLTMuODIzCgkJQzQuMDcxLDAuMjExLDAsNC4yODEsMCw5LjMwMWMwLDUuMDIxLDQuMDcxLDkuMDksOS4wOTEsOS4wOWMwLjE4NywwLDAuMzcyLTAuMDA3LDAuNTU3LTAuMDE4bC01LjgwMiw1LjgwMkw1LjQ2LDI1Ljc4OXoiLz4KCTxwYXRoIGNsYXNzPSJzdDIiIGQ9Ik0xNi40OTksMTQuNTY3YzEuMDU5LTEuNDg2LDEuNjgyLTMuMzAyLDEuNjgyLTUuMjY2UzE3LjU2LDUuNTIsMTYuNSw0LjAzMwoJCWMtMS4wNTgsMS40ODctMS42OCwzLjMwNC0xLjY4LDUuMjY4QzE0LjgyLDExLjI2NCwxNS40NDEsMTMuMDgxLDE2LjQ5OSwxNC41NjciLz4KPC9nPgo8L3N2Zz4K'); // l-quote.svg
	// 	margin-right: -40px;
	// }
	padding-right: 40px;
}
%blockquote-source {
	margin-top: 1rem;
	//@include small-heading-font;
	font-family: 'dolly-new', serif;
	font-weight: 400;
}

/******************************************************************************************
Molehill avenir fonts
******************************************************************************************/
$fonts_dir: '/assets/fonts/';

@font-face {
	font-family:"Avenir regular";
	src: local(''),
        url($fonts_dir + 'Avenir-light.woff2') format('woff2'), 
        url($fonts_dir + 'Avenir-light.woff') format('woff'); 
	font-display:auto;
	font-style:normal;
	font-weight:normal;
}
@font-face {
	font-family:"Avenir italic";
	src: local(''),
        url($fonts_dir + 'Avenir-light-oblique.woff2') format('woff2'), 
        url($fonts_dir + 'Avenir-light-oblique.woff') format('woff'); 
	font-display:auto;
	font-style:normal;
	font-weight:normal;
}
@font-face {
	font-family:"Avenir bold";
	src: local(''),
        url($fonts_dir + 'Avenir-heavy.woff2') format('woff2'), 
        url($fonts_dir + 'Avenir-heavy.woff') format('woff'); 
	font-display:auto;
	font-style:normal;
	font-weight:normal;
}
@font-face {
	font-family:"Avenir bold italic";
	src: local(''),
        url($fonts_dir + 'Avenir-heavy-oblique.woff2') format('woff2'), 
        url($fonts_dir + 'Avenir-heavy-oblique.woff') format('woff'); 
	font-display:auto;
	font-style:normal;
	font-weight:normal;
}
@mixin avenirRegular {
	font-family: 'Avenir regular', sans-serif;
	font-weight:normal;
}
@mixin avenirBold {
	font-family: 'Avenir bold', sans-serif;
	font-weight:normal;
}
@mixin heroTitle {
	// 48px/48px
	font-size: 6rem;
	line-height: 1;
	//@include avenirBold;
	@include breakpoint(smallish) {
		// 60px/60px
		font-size: 7.5rem;
	}
}
@mixin crossHeading {
	// 24px/32px
	font-size: 3rem;
	line-height: 1.5;
	font-weight: normal;
	//@include avenirBold;
	p {
		font-size: 3rem;
		line-height: 1.5;
	}
	@include breakpoint(medium) {
		// 26px/36px
		font-size: 3.25rem;
		line-height: 1.384615;
		p {
			font-size: 3.25rem;
			line-height: 1.384615;
		}
	}
}
@mixin navyLink {
	color: $navy;
	&:hover {
		color: darken($navy, 10%);
	}
}
.main__molehillMtn {
	font-family: 'Avenir regular', sans-serif;
	font-weight: normal;
	-webkit-font-smoothing: antialiased; // less blurry fonts for chrome
	-moz-osx-font-smoothing: grayscale; // less blurry fonts for FF
	.bold {
		@include avenirBold
	}
	* {
		color: $navy;
	}
	a {
		p &, li &, span & {
			@include navyLink;
		}
		* {
			color: $navy;
		}
	}
	h2:not(.body__heading) {
		@include crossHeading;
		@include avenirBold;
	}
	h2 {
		&.body__heading, &.links__heading {
			@include big-heading-font;
			@include avenirRegular;
		}
	}
	.body__heading, .links__heading {
		@include big-heading-font;
		@include avenirRegular;
	}
	p {
		@include body-font;
	}
	.wysiwyg {
		.heading {
			@include big-heading-font;
			@include avenirRegular;
		}
		.standfirst {
			@include crossHeading;
		}
		ul {
			li {
				&:before {
					background: $purple;
				}
			}
		}
		ol {
			li {
				border-top: 1px solid rgba($navy, .6);
				&:before {
					color: $purple;
					font-family: 'Avenir regular', serif;
					font-weight: normal;
				}
				&:first-child {
					border-top: none;
				}
			}
		}
	}
	.btn {
		color: $white;
		background-color: $navy;
		border-radius: 200px;
		box-shadow: none;
		&:hover {
			background-color: darken($navy, 10%);
			box-shadow: none;
		}
	}
	.owl-dots {
        .owl-dot {
            span {
                background-color: $purple;
            }
			&.active span , &:hover span {
				background: $navy;
			}
        }
    }
}