.logo-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    padding-left: 24px;
    padding-right: 24px;
    box-sizing: border-box;    
    margin-bottom: 6rem;
    .logo {
        flex: 0 0 45%;
        max-width: 45%; 
        .image {
            padding: 0;
            margin: 0;
        }
    }
    max-width: $text-max-width;
    @include breakpoint(large) {
		width: 50%;
		margin-right: 16.666666%;
        float: right;
        &__max {
            justify-content: space-around;
            width: auto;
		    margin-right: 0;
            float: none;
            max-width: none;
            .logo {
                flex: 0 0 22.5%;
                max-width: 22.5%; 
            }
        }
	}
}