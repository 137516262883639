.accordion__fullwidth {
  &--header {
    float: none;
    padding: 0;
    width: 100%;
    &:after {
      border-top: none;
    }
    h2:not(.body__heading) {
      margin-top: 3.5rem;
      margin-bottom: 1.5rem;
    }
  }
  &--block {
    border-bottom: 1px solid rgba(0,0,0,.15);
    .accordion__heading:not(.body__heading) {
      margin-bottom: 0;
    }
  }
}
