.image {
	@extend %gutters;
	margin-top: 5rem;
	margin-bottom: 6rem;
	.image + &, .video + & {
		margin-top: 0; // when images/videos follow each other, don't let their margins stack
	}
	&__img {
		display: block;
		width: 100%; // Safari doesn't load in larger responsive images when you widen your browser, so this at least makes sure the layout doesn't get messed up
	}
	&--full-width {
		float: left; // make v margins work against text
		width: 100%;
	}
	&--side-column {
		@extend %sidebar-column;
		padding-top: 1rem;
	}
	&--text-column {
		@extend %text-column;
	}
}
.section__image--multi-column {
	.image-frame-out {
		max-width: 480px;
		margin: 0 auto;
	}
	.image-frame-in {
		padding: 0 25% 1rem 25%;
	}
	@include breakpoint(medium) {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		.image--multi-column {
			//margin-bottom: 0;
			flex: 0 0 50%;
			max-width: 50%;
		}
		.image-frame-out {
			position: relative;
    		padding-top: 70%;
    		width: 100%;
			max-width: none;
			// border-bottom: 1px solid $slate-30;
			// margin-bottom: 3rem;
		}
		.image-frame-in {
			position: absolute;
    		top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 0 22.5%;
		}
	}
	@include breakpoint(large) {
		.image--multi-column {
			margin-bottom: 0;
			flex: 0 0 33%;
			max-width: 33%;
		}
	}
}