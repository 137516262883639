.cookie-popup {
    display: none;
    position: fixed;
    width: 100%;
    height: 14rem;
    bottom: 0;
    background-color: $new_text_color;
    z-index: 1000;
    .popup-grid {
        display: flex;
        position: relative;
        height: 14rem;
        max-width: 150rem;
        padding: 0 3rem;
        margin: 0 auto;
        align-items: center;
        justify-content: space-between;
        .popup-text {
            p {
                @include body-bold-font;
                color: $white;
                display: inline-block;
            }
            a {
                @include body-bold-font;
                color: $white;
                text-decoration: underline;
                text-decoration-color: $white;
            }
        }
        .popup-btn {
            #cookie-button:hover {
                cursor: pointer;
            }
        }
        .btn--yellow {
          color: $new_text_color;
          background-color: $white;
          &:hover {
            background-color: $slate-10-rgb;
          }
        }
    }
}
@media only screen and (max-width: 680px) {
    .cookie-popup {
        height: 18rem;
        .popup-grid {
            height: 18rem;
            max-width: 45.5rem;
            flex-direction: column;
            justify-content: center;
            .popup-text {
                margin-bottom: 1rem;
            }
        }
    }

}
