.download {
    &:not(.mobile) {
        display: none;
    }
    @media screen and (min-width: $large-screen) {
        margin-bottom: 4rem;
        &:not(.mobile) {
            display: block;
        }
        &.mobile {
            display: none;
        }
    }
}