.accordion {
	@extend %text-column;
	@extend %gutters;
	margin-bottom: 3rem;
	h1 {
		@include big-heading-font;
		margin-bottom: 4rem;
	}
	& > p {
		@include medium-text-font;
		margin-bottom: 5rem;
	}
	&:after {
		content: '';
		display: block;
		border-top: 1px solid $slate-55-rgb;
	}
	&__heading {
		@include medium-body-font;
		color: $green-strong;
		border-top: 1px solid $slate-55-rgb;
		padding: 2rem 4rem 2rem 0;
		position: relative;
		cursor: pointer;
		background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDIzLjAuNiwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHdpZHRoPSIyNHB4IiBoZWlnaHQ9IjI0cHgiIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMjQgMjQ7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4KPHN0eWxlIHR5cGU9InRleHQvY3NzIj4KCS5zdDB7ZmlsbDojMDBBNTkwO30KPC9zdHlsZT4KPHBhdGggY2xhc3M9InN0MCIgZD0iTTI0LDEzSDBsMC0yaDI0VjEzeiIvPgo8L3N2Zz4K') right 0 top 3rem no-repeat;
		&__plus {
			position: absolute;
			top: 3rem;
			right: 0;
			transition: transform .4s;
			transform: rotate(90deg);
			.js-accordion-heading--is-open & {
				transform: rotate(0);
			}
			path {
				fill: $green;
			}
		}
	}
	&__content {
		padding-bottom: 3rem;
	}
	.JS & {
		&__content {
			display: none;
		}
	}
}

body.asd_uk .block__accordion {
	background-color: $slate-05;
}
