.join-discover {
	@extend %section-margins;
	border-top: 1px solid $green;
	background-color: $green-10;
	&__heading {
		@extend %text-column;
		@extend %gutters;
		margin-bottom: 5rem;
		@include big-heading-font;
	}
	&__intro {
		@extend %text-column;
		@extend %gutters;
		margin-bottom: 3rem;
	}
	&__form {
		@extend %text-column;
	}
}
