.mh-mtn-header {
    background-color: $navy;
    * {
        color: $white;
    }
    .mh-mtn-header--image {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
    }
    .mh-mtn-header--svg {
        display: block;
        max-width: 100%;
        height: auto;
        margin: 0 auto;
    }
    .mh-mtn--partners-tag {
        @include small-body-font;
        @include avenirBold;
        margin-bottom: 8px;
    }
    .mh-mtn-header--title {
        display: inline-block;
        @include big-heading-font;
        @include avenirRegular;
        margin-bottom: 8px;
    }
    .logoPartner {
        width: auto;
        max-height: 48px;
    }
    .mh-mtn-header--content {
        position: relative;
		max-width: 1296px;
		box-sizing: border-box;
		padding: 24px;
        margin: 0 auto;
	}    
    .mh-mtn-header--inner {
        position: relative;
    }
    @include breakpoint(smallish) {
        .mh-mtn-header--svg {
            margin-left: auto;
            margin-right: 48px;
        }
        .mh-mtn-header--content {
            padding: 32px 48px;
        }
    }
    @include breakpoint(medium) {
        .mh-mtn-header--title {
            padding-right: 24px;
            border-right: 1px solid $white;
            margin-right: 24px;
            margin-bottom: 0;
            display: flex;
            align-items: center;
        }
        .mh-mtn--partners-tag {
            margin-bottom: 0;
            margin-right: 12px;
        }
        .logoPartner {
            max-height: 64px;
        }
        .mh-mtn-header--content {
            display: flex;
            padding: 24px 48px;
        }
        .mh-mtn--partners {
            display: flex;
            align-items: center;
        }
    }
    @include breakpoint(huge) {
        .mh-mtn-header--svg {
            margin-right: 5%;
        }
    }
}