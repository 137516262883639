.ajax-success-message {
	background: #bff1ba;
	background: $yellow;
	padding: 3rem 3rem 4rem;
	* {
		color: $text-color !important;
	}
	&__heading {
		@include medium-heading-font;
		margin-bottom: 3rem;
	}
	&__p {
		@include body-font;
	}
}