.owl-dots {
	text-align: center;
	line-height: 1rem;
	.owl-dot {
		display: inline-block;
		padding: 1.5rem;
		margin: -1.5rem 0; // cancels out space caused by vertical padding
		span {
			display: block;
			width: 3rem;
			height: 3rem;
			border-radius: 50%;
			background: $green;
		}
		&.active span , &:hover span {
			background: $pink-strong;
			.stats--blue & {
				background: #fff;
			}
		}
	}
}

// show carousels that have been disabled - based on http://stackoverflow.com/questions/28251644/disabling-owl-carousel-at-a-specific-viewport-width
.owl-carousel.off {
    display: block;
}
