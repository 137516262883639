.cta {
	padding: 2.5rem 0 3.5rem;
	background-color: $green-strong;
	text-align: center;
	&__text {
		@extend %gutters;
		@include medium-heading-font;
		color: $white;
		margin-bottom: 1.75rem;
	}
	.btn {
		color: $green-strong;
		background-color: $white;
		&:hover {
			background-color: $slate-10-rgb;
		}
	}
	&__btn-wrap {
		@extend %gutters;
	}
	@include breakpoint(large) {
		padding: 1.25rem 0 1.75rem;
		&__content {
			width: 95%;
			margin: 0 auto;
			display: flex;
			justify-content: center;
			text-align: left;
		}
		&__text {
			margin: 1.5rem 0 0;
			padding-left: 0; // no gutters in design here
		}
		&__btn-wrap {
			// if 2 lines of text, center button vertically
			display: flex;
			flex-direction: column;
			justify-content: center;
			padding-right: 0; // no gutters in design here
		}
	}
}
