.hero {
    position: relative;
    * {
        color: $white;
    }
    .image {
        padding: 0;
        margin: 0;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        overflow: hidden;
    }
    .heroImage {
        display: block;
        position: absolute;
        top: -9999px;
        right: -9999px;
        bottom: -9999px;
        left: -9999px;
        width: 100%;
        height: 100%;
        margin: auto;
        -o-object-fit: cover;
        object-fit: cover;
    }
    .heroContent {
        position: relative;
        @extend %gutters;
        margin-top: 5rem;
        margin-bottom: 5rem;
    }
    .heroTitle {
        @include heroTitle;
        margin-bottom: 5rem;
        &:last-child {
            margin-bottom: 0;
        }
    }
    .crossHeading {
        @include crossHeading;
        margin-bottom: 4rem;
        &:last-child {
            margin-bottom: 0;
        }
    }
    .smallText {
        @include small-body-font;
        margin-bottom: 2rem;
        &:last-child {
            margin-bottom: 0;
        }
    }
    @include breakpoint(medium) {
		.heroContent {
            min-height: 42.5rem;
            display: flex;
            align-items: center;
            .column {
                width: 50%;
			    padding-right: 3rem;
                box-sizing: border-box;
                padding-top: 5%;
                padding-bottom: 5%;
            }
        }
	}
}
.intro__molehillMtn {
    background-color: $lightGreen;
    .introLogos {
        margin-top: 5rem;
        &:first-child {
            margin-top: 0;
        }
        a {
            margin-right: 1rem;
            &:last-child {
                margin-right: 0;
            }
        }
        img {
            display: inline-block;
            width: auto;
            margin-right: 1rem;
            &:last-child {
                margin-right: 0;
            }
        }
    }
}
.features {
    background-color: $lightBlue;
    padding-bottom: 5rem;
    .featuresContent {
        @extend %gutters;
        margin-top: 6rem;
        margin-bottom: 5rem;
    }
    .feature {
        @extend %gutters;
    }
    .featureImage {
        max-width: 46rem; //368px
        margin: 4rem auto;
    }
    @include breakpoint(medium) {
        padding-bottom: 0;
        .feature {
            float: left;
            width: 33.333333%;
        }
        .featureImage {
            margin-left: 0;
            margin-bottom: 0;
        }
    }
}
.relatedEntries {
    background-color: $lightGreen;
}