html, body {
	height: 100%;
}

.section-wrap {
	@extend %screen-margins;
	max-width: $max-screen-width;
	margin: 0 auto;
	@if $old-ie {width: $fixed-width;}
	@include clearfix;
}

/******************************************************************************************
STICKY FOOTER
******************************************************************************************/

body {
	@include box;
	display: flex;
	flex-direction: column;
	height: 100vh;
}
.main-content {
	flex: 1 0 auto;
	min-height: 1%; // force IE to reflow and get flexbox footer right
}
// stops footer collapsing on Safari iPad
.site-footer {
	flex-shrink: 0;	
}

/******************************************************************************************
TEXT/IMAGE COLUMNS
******************************************************************************************/

%text-column {
	max-width: $text-max-width;
	@include breakpoint(large) {
		width: 50%;
		margin-right: 16.666666%;
		float: right;
	}
}
%sidebar-column {
	max-width: $sidebar-max-width;
	@include breakpoint(large) {
		width: 33.333333%;
		float: left;
		margin-top: 0 !important;
	}
}
%four-column {
	max-width: none;
	@include breakpoint(large) {
		width: 66.666666%;
		float: right;
	}
}
%section-margins {
	padding-top: 6rem;
	padding-bottom: 8rem;	
}

/******************************************************************************************
GRID
******************************************************************************************/

%gutters {
	padding-left: $gutter-half-width;
	padding-right: $gutter-half-width;
	box-sizing: border-box; -moz-box-sizing:border-box; *behavior: url(/js/third-party/boxsizing.htc);
}
%left-gutter {
	padding-left: $gutter-half-width;
	box-sizing: border-box; -moz-box-sizing:border-box; *behavior: url(/js/third-party/boxsizing.htc);
}
%right-gutter {
	padding-right: $gutter-half-width;
	box-sizing: border-box; -moz-box-sizing:border-box; *behavior: url(/js/third-party/boxsizing.htc);
}
%negative-gutters {
	margin-left: -$gutter-half-width;
	margin-right: -$gutter-half-width;
}
%screen-margins {
	padding-left: $screen-margin-small; padding-right: $screen-margin-small;
	@include breakpoint(smallish) {padding-left: $screen-margin-smallish; padding-right: $screen-margin-smallish}
}
%screen-margins-with-gutters {
	padding-left: $screen-margin-small-with-gutter; padding-right: $screen-margin-small-with-gutter;
	@include breakpoint(smallish) {padding-left: $screen-margin-smallish-with-gutter; padding-right: $screen-margin-smallish-with-gutter}
}
%negative-screen-margins {
	margin-left: -$screen-margin-small; margin-right: -$screen-margin-small;
	@include breakpoint(smallish) {margin-left: -$screen-margin-smallish; margin-right: -$screen-margin-smallish}
}
%negative-screen-with-gutters {
	margin-left: -$screen-margin-small-with-gutter; margin-right: -$screen-margin-small-with-gutter;
	@include breakpoint(smallish) {margin-left: -$screen-margin-smallish-with-gutter; margin-right: -$screen-margin-smallish-with-gutter}
}