#mc_embed_signup {
	@extend %text-column;
	@extend %gutters;
	.follow & {
		margin: 0;
		padding: 0;
		width: 100%;
	}
	.indicates-required {
		@include small-text-font;
		margin-bottom: 3rem;
	}
	.mc-field-group {
		margin-bottom: 3rem;
	}
	label {
		@extend %label;
	}
	input[type="text"], input[type="email"] {
		@extend %textInput;
	}
	select {
		@extend %select;
	}
	// these are headings in Mailchimp forms
	strong {
		@include body-bold-font;
		display: block;
		margin-bottom: 1rem;
	}
	p {
		margin-bottom: 3rem;
	}
	.input-group {
		padding-bottom: 2rem;
		input {
			float: left;
			font-size: 16px;
			position: relative;
			top: 10px;
		}
		label {
			@include body-font;
			margin-left: 3rem;
			display: block;
		}
	}
	.mce_inline_error {
		background: lighten(red, 47%);
		border-color: $error-color !important;
	}
	div.mce_inline_error {
		@include body-font;
		font-weight: 400 !important;
		background: none !important;
		color: $error-color !important;
		padding: 0 !important;
		margin: 1rem 0 3rem !important;
	}
	.response {
		@include small-text-font;
		margin-bottom: 3rem;
		border: 1px solid $teal;
		background-color: lighten($teal, 40%);
		padding: 1.5rem 2rem;
		&#mce-error-response {
			border-color: $error-color;
			background-color: lighten($error-color, 47%);
		}
	}
}