.biography {
	border-top: 1px solid $green;
	background-color: $green-10;
	&__section {
		@extend %section-margins;
		@include clearfix;
	}
	&__heading {
		@extend %sidebar-column;
		@extend %gutters;
		@include body-bold-font;
		margin-bottom: 3rem;
	}
	&__content {
		@extend %four-column;
		@extend %gutters;
		&--image {
			.image-frame-out {
				position: relative;
				width: 180px;
				padding-top: 180px;
			}
			.image-frame-in {
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
				margin-bottom: 0;
				overflow: hidden;
				border-radius: 50%;
			}
			.biography__image {
				position: absolute;
				top: -9999px;
				right: -9999px;
				bottom: -9999px;
				left: -9999px;
				width: 100%;
				height: 100%;
				margin: auto;
				object-fit: cover;
			}
			@include breakpoint(medium) {
				.image-frame-out {
					float: right;
				}
				p {
					margin-right: 26rem;
				}
			}
		}
		&--small p {
			@include small-text-font;
		}
		&.wysiwyg {
			p, ol, ul {
				&:last-child {
					margin-bottom: 0;
				}
			}
		}
	}
	&__image {
		margin-bottom: 3rem;
	}
}
