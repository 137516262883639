.illustrated-list {
	@extend %section-margins;
	&__heading {
		@extend %gutters;
		margin-bottom: 5rem;
		@include big-heading-font;
	}
	&__item {
		@include clearfix;
		&:not(:last-of-type) {
			&:after {
				content: '';
				display: block;
				margin: 4rem $gutter-half-width 3rem;
				border-bottom: 1px solid $slate-30;
			}
		}
		&__image {
			@extend %gutters;
			margin-bottom: 3rem;
			padding-top: 1rem;
			position: relative;
			&__img {
				display: block;
			}
		}
		&__content {
			@extend %gutters;
		}
	}
	@include breakpoint(medium) {
		&__item {
			&__image {
				float: left;
				width: 50%;
				padding-bottom: 1rem;
			}
			&__content {
				width: 50%;
				margin-left: 50%;
				padding-bottom: 1rem;
			}
		}
	}
	@include breakpoint(large) {
		&__item {
			&__image {
				width: 33.333333%;
				max-width: $sidebar-max-width;
			}
			&__content {
				width: 66.666666%;
				margin-left: 33.333333%;
				max-width: $text-max-width;
			}
		}
	}
}
