.page-header {
	position: relative;
	&__link {
		* {
			transition: .5s opacity;
		}
		&:hover * {
			opacity: 0.85;
		}
	}
	&__discover-banner {
		background-color: $new_text_color;
		padding: 3rem 0;
		.page-header__text__tagline--title {
			color: $white;
		}
		&__logo {
			padding: 0 $gutter-half-width;
			&__svg {
				max-width: 100%;
			}
		}
	}
	&__hero {
		background-size: cover;
		height: 28rem;
		@include breakpoint(smallish) {
			height: 39rem;
			// --large just makes image a bit deeper at this breakpoint, eg. on personal stories
			&--large {
				height: 50rem;
			}
		}
		@include breakpoint(medium) {
			height: 75rem;
			&.fallback-graphic {
				height: 300px;
				background-color: $slate-55-rgb;
			}
		}
	}
	&__text {
		padding: 5rem 0;
		background: $pink-55;
		&.ndei-color {
			background-color: $gold;
		}
		&__section {
			@extend %gutters;
			@include body-font;
			font-weight: 600;
			color: $new_text_color;
			margin-bottom: 3rem;
			&__link {
				color: $new_text_color;
				background-image: none;
			}
		}
		&__heading {
			@extend %gutters;
			@include big-heading-font;
			color: $new_text_color;
		}
		&__subheading {
			@extend %gutters;
			@include small-text-font;
			color: $new_text_color;
			margin-top: 1rem;
		}
		&__tagline {
			@extend %gutters;
			@include medium-text-font;
			color: $new_text_color;
			margin-top: 1rem;
			&--title {
				@include medium-heading-font;
				color: $new_text_color;
				margin-left: 0;
				margin-right: auto;
			}
		}
		&__cats {
			@extend %gutters;
			margin-top: 1rem;
			&__link {
				@include no-link;
			}
			&__cat {
				@include small-heading-font;
				background: $new_text_color;
				color: $white;
				display: inline-block;
				padding: .625rem 1rem .375rem;
				margin: 1rem 1rem 0 0;
			}
		}
		@include breakpoint(medium) {
			// &--overlay {
			// 	position: absolute;
			// 	bottom: 0;
			// 	left: 0;
			// 	right: 0;
			// 	opacity: .85;
			// }
			&--home {
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				background: none;
				padding-top: 10rem;
				.page-header__text__heading {
					color: $new_text_color;
					width: 50%;
					margin-left: 0;
				}
			}
			&__heading, &__subheading, &__tagline, &__cats {
				width: 83.333333%;
			}
		}
		@include breakpoint(large) {
			&__section {
				float: left;
				width: 25%;
				background-image: none;
			}
			&__heading, &__subheading, &__tagline, &__cats {
				width: 50%;
				margin-left: 33.333333%;
			}
			&--home .page-header__text__heading {
				font-size: 7rem;
				line-height: 1.142857;
			}
		}
	}
	&--asd {
		position: relative;
    	height: auto;
		.page-header__text {
      		height: auto;
			color: $new_text_color;
			background-color: $pink-strong-55;
      		padding: 6rem 0;
			&__section, &__heading, &__subheading, &__tagline {
				color: $new_text_color;
			}
			&__cats {
				&__cat {
					color: $white;
					background-color: $new_text_color;
				}
			}
			.btn {
				color: $green-strong;
				background-color: $white;
				&:hover {
					background-color: $slate-10-rgb;
				}
			}
		}
		.page-header__hero {
			position: relative;
			background-color: $green-strong;
			height: 60rem;
			@include breakpoint(large) {
				height: 72.5rem;
				.asd-uk-graphic {
				width: 50%;
				height: 100%;
				margin: 0 0 0 50%;
						background-image: url('/assets/img/asd-uk-graphic.svg');
						background-repeat: no-repeat;
						background-position: left 24px top -247px;
						background-size: 1095px auto;
				}
			}
		}
		&--home {
			.section-wrap {
				margin: 0 auto;
			}
			.page-header__text {
				background-color: transparent;
				position: absolute;
				top: 0;
				bottom: 0;
				width: 100%;
				display: flex;
				align-items: center;
			}
			.page-header__text__heading {
				color: $white;
				margin: 32px 0 40px;
			}
			.page-header__text__subheading {
				margin: 0;
				p {
					@include medium-text-font;
					color: #FFF;
					font-weight: 600;
					margin: 0 0 24px;
				}
			}
		}
		&--researchStudyListing {
			.page-header__hero {
				@include breakpoint(medium) {
	        height: 50rem;
					padding-bottom: 27.5rem;
	      }
				@include breakpoint(large) {
					height: 60rem;
				}
			}
		}
	}
}
