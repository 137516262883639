.site-footer {
	@extend %section-margins;
	background-color: $new_text_color;
	* {
		color: #fff;
	}
	a {
		@include no-link;
		&:hover {
			color: $slate-10-rgb;
		}
	}
	.section-wrap {
		@extend %gutters;
	}
	&__contact {
		margin-bottom: 3rem;
		&__span {
			font-family: 'acumin-pro', sans-serif;
			font-weight: 700;
		}
	}
	&__links {
		margin-bottom: 3rem;
		@include clearfix;
		&__link {
			float: left;
			&:not(:last-of-type) {
				padding-right: 1rem;
				border-right: 1px solid $slate-55-rgb;
				margin-right: 1rem;
			}

		}
	}
	&__logos {
		border: 1px solid $slate-55-rgb;
		border-width: 1px 0;
		padding: 3rem 0;
		margin-bottom: 3rem;
		@include clearfix;
		&__logo {
			float: left;
			margin-right: 3rem;
			height: 5rem;
			@include breakpoint(smallish) {
				height: auto;
			}
		}
	}
	&__small-print, &__credit {
		@include small-text-font;
		color: hex($gray-15);
	}
	&__small-print {
		margin-bottom: 3rem;
	}
	@include breakpoint(large) {
		&__small-print {
			float: left;
		}
		&__credit {
			float: right;
		}
	}
}
