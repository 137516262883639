/******************************************************************************************
COLORS
******************************************************************************************/

$gray-75: #454545;
$gray-30: rgba(0, 0, 0, .3);
$gray-15: rgba(0, 0, 0, .15);
$gray-8: rgba(0, 0, 0, .3);

$yellow: #ffe58f;
$light-yellow: #fff9e8;

$blue: #81b3e7;
$light-blue: #c0d9f3;
$very-light-blue: #f4f8fd;
$dark-blue-text: #253d7d;
$dark-blue: #1f3369;
$teal: #5daca6;
$turquoise: #22b7c7;
$text-color: $gray-75;
$error-color: red;

$asd-purple: #4F0250;
$cookie-orange: #ffe58f;

/******************************************************************************************
New colours
******************************************************************************************/
$white: #ffffff;
$new_text_color: #1d1d1d;

$slate: #00444f;
$slate-75: rgba($slate, .75);
$slate-55: rgba($slate, .55);
$slate-55-rgb: #73989e;
$slate-40: rgba($slate, .40);
$slate-40-rgb: #9fb3b8;
$slate-30: rgba($slate, .30);
$slate-30-rgb: #b3c7ca;
$slate-10: rgba($slate, .10);
$slate-10-rgb: #e6eced;
$slate-05: rgba($slate, .05);

$green: #00a590;
$green-10: rgba($green, .10);
$green-strong: #008279;
$pink: #f08ba9;
$pink-10: rgba($pink, .10);
$pink-55: rgba($pink, .55);
$pink-strong: #eb648d;
$pink-strong-55: rgba($pink-strong, .55);
$gold: #A88E4F;

/******************************************************************************************
Mohill Mountain colors
******************************************************************************************/
$navy: #283582;
$blueElectric: #4BF0E7;
$blueCyan: #4ECAE2; 
$seaGreen: #5AB8A6; 
$lightGreen: #E9F7DB;
$lightBlue: #E9FBFF; 
$pinkPastel: #FFB6F3; 
$purple: #9376BB;

/******************************************************************************************
LAYOUT VARIABLES
******************************************************************************************/

$sidebar-max-width: 368px; // 320px + gutters
$text-max-width: 624px; // 576px + gutters

// SCREEN SIZES

$max-screen-width: 1248px;
$smallish-screen: 480px;
$medium-screen: 768px;
$large-screen: 992px;
$huge-screen: 1200px;
$max-screen: 1400px;

$nav-breakpoint: 1170px;
$header-height-small: 86px;
$header-height-medium: 93px;

// GUTTERS

$gutter-half-width: 24px;
$screen-margin-small: 0;
$screen-margin-smallish: 24px;
$screen-margin-small-with-gutter: $screen-margin-small + $gutter-half-width;
$screen-margin-smallish-with-gutter: $screen-margin-smallish + $gutter-half-width;
