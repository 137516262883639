.search-results {
	@extend %section-margins;
	&__heading {
		@extend %four-column;
		@extend %gutters;
		@include big-heading-font;
		margin-bottom: 6rem;
	}
	&__form {
		@extend %four-column;
		@extend %gutters;
		position: relative;
		&__input {
			@extend %textInput;
		}
		&__submit {
			height: 6rem;
			width: 6rem;
			text-indent: -999em;
			border: none;
			position: absolute;
			top: 1px;
			right: $gutter-half-width;
			cursor: pointer;
			// search.blue.svg
			background: url(data:image/svg+xml;charset=utf-8;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMCIgaGVpZ2h0PSIyMCIgdmlld0JveD0iMCAwIDIwIDIwIj48cGF0aCBmaWxsPSJub25lIiBzdHJva2U9IiMyNTNkN2QiIHN0cm9rZS13aWR0aD0iMiIgZD0iTTEyLjEgMTIuMWw3LjIgNy4yIi8+PGNpcmNsZSBjeD0iNy44IiBjeT0iNy44IiByPSI2LjgiIGZpbGw9Im5vbmUiIHN0cm9rZT0iIzI1M2Q3ZCIgc3Ryb2tlLXdpZHRoPSIyIi8+PC9zdmc+)
			 center center no-repeat;
			 &:hover {
			 	background-image: url(data:image/svg+xml;charset=utf-8;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMCIgaGVpZ2h0PSIyMCIgdmlld0JveD0iMCAwIDIwIDIwIj48cGF0aCBmaWxsPSJub25lIiBzdHJva2U9IiMyMmI3YzciIHN0cm9rZS13aWR0aD0iMiIgZD0iTTEyLjEgMTIuMWw3LjIgNy4yIi8+PGNpcmNsZSBjeD0iNy44IiBjeT0iNy44IiByPSI2LjgiIGZpbGw9Im5vbmUiIHN0cm9rZT0iIzIyYjdjNyIgc3Ryb2tlLXdpZHRoPSIyIi8+PC9zdmc+);
			 }
		}
	}
	&__list {
		@extend %four-column;
		@extend %gutters;
		&__summary {
			margin-bottom: 3rem;
		}
	}
	&__entry {
		margin-bottom: 3rem;
		@include clearfix;
		&__image {
			width: 10rem;
			float: left;
			margin-top: 1rem;
		}
		&__heading {
			@include body-bold-font;
			margin-left: 12rem;
			&__link {
				@include link;
			}
		}
		&__teaser {
			@include small-text-font;
			margin-left: 12rem;
		}
	}
	@include breakpoint(medium) {
		&__entry {
			&__image {
				width: 20rem;
			}
			&__heading, &__teaser {
				margin-left: 22rem;
			}
		}
	}		
}