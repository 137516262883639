.filter-section {
  display:flex;
  flex-direction: column;
  flex-wrap: wrap;
  .col {
    font-size: 2.5rem;
    padding: 1rem 24px 0;
    margin-bottom: 3rem;
    box-sizing: border-box;
  }
  @include breakpoint(medium) {
    flex-direction: row;
    .col {
      &__6 {
        width: 50%;
      }
    }
  }
}
.filter-block {
  padding-top: 4rem;
  padding-bottom: 4rem;
}
.filter-items, .filter-no-result {
  @include body-bold-font;
  margin-left: 24px;
  margin-right: 24px;
  margin-bottom: 48px;
}
.filter-button {
  @include body-bold-font;
  display: inline-block;
  cursor: pointer;
  margin-left: 24px;
  margin-right: 24px;
  margin-bottom: 4px;
  transition: all .3s ease-in-out;
  &:after {
    content: '';
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 8px;
    border-style: solid;
    border-width: 12px 8px 0 8px;
    border-color: $text-color transparent transparent transparent;
    transition: all .3s ease-in-out;
  }
  &.active {
    &:after {
      transform: rotate(180deg);
    }
  }
  &:hover {
    color: $turquoise;
    &:after {
      border-color: $turquoise transparent transparent transparent;
    }
  }
}
.filter-row {
  display: none;
  padding-left: 24px;
  padding-right: 24px;
  font-size: 2rem;
  line-height: 1.4;
	font-weight: 600;
  h3 {
    @include body-bold-font;
    padding-bottom: 12px;
  }
  .row {
    @include breakpoint(medium) {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .column {
        &_6 {
          flex: 0 1 48.5%;
        }
      }
      &-btns {
        justify-content: flex-start;
      }
    }
    .btn {
      cursor: pointer;
      margin-right: 16px;
      &-clear {
        background-color: $gray-30;
        box-shadow: 0 0.5rem 0 0 $gray-75;
        &:hover {
          background-color: rgba(0,0,0,.5);
        }
      }
    }
  }
  .column {
    padding-top: 16px;
    padding-bottom: 16px;
    border-bottom: 1px solid $gray-15;
    &:last-child {
      border-bottom: none;
    }
    /*@include breakpoint(medium) {
      &:nth-last-child(2) {
        border-bottom: none;
      }
    }*/
  }
  .filter {
    display:inline-block;
    margin-bottom: 4px;
    label {
      display: block;
      padding: 6px 12px;
      border: 1px solid $gray-30;
      background-color: #ffffff;
      box-shadow: inset 0 3px 0 rgba(0,0,0,.05);
      cursor: pointer;
      &:hover {
        background-color: $very-light-blue;
      }
    }
    input {
      display: none;
      &:checked ~ label {
        color: #ffffff;
        background-color: $dark-blue;
      }
    }
  }
}
