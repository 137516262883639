.follow {
	@extend %section-margins;
	background-color: $slate;
	*:not(input) {
		color: #fff;
	}
	&__content {
		@extend %gutters;
	}
	&__heading {
		@include big-heading-font;
		color: $yellow;
		margin-bottom: 5rem;
	}
	&__subheading {
		@include body-bold-font;
		clear: both;
		border-top: 1px solid $slate-55-rgb;
		padding: 3rem 0;
		&--newsletter {
			cursor: pointer;
		}
		&__arrow {
			float: right;
			transition: transform .4s;
			.follow__subheading--is-active & {
				transform: rotate(-180deg);
			}
		}
	}
	&__newsletter-form {
		@include clearfix;
		padding-bottom: 3rem;
		&__p {
			margin-bottom: 3rem;
		}
		.form {
			padding: 0;
			.btn {
				background-color: $white;
				color: $green-strong;
				cursor: pointer;
				&:hover {
					background-color: $slate-10-rgb;
				}
			}
		}
		.JS & {
			display: none;
		}
	}
	&__networks {
		margin: -1rem 0 3rem;
		@include clearfix;
		&__network {
			float: left;
			margin-right: 3rem;
			path {
				fill: $white;
			}
			&:hover path {
				fill: $slate-10-rgb;
			}
		}
	}
	&__p {
		@include body-bold-font;
		a {
			@include link-white;
		}
	}
	@include breakpoint(medium) {
		&__content {
			width: 66.666666%;
			margin-left: 33.333333%;
		}
	}
	@include breakpoint(large) {
		&__content {
			width: 50%;
		}
	}
}
