.references__index:not(.jump-to__link) {
    font-size: .5em;
    vertical-align: 1em;
    margin-left: -.5em;
    text-decoration: none;
    background-image: none;
    font-weight: bold;

    &:before {
        content: '[';
    }
    &:after {
        content: ']';
    }
}

.references {
    border-top: 1px solid $slate-55-rgb;
    padding-top: 5rem;

    .wysiwyg {
        .references__list {
            li {
                padding: 0 0 1rem 1em;
                border-top: 0;
                &:before {
                    font-size: inherit;
                    line-height: inherit;
                    width: 1em;
                    margin-left: -1em;
                }
            }
        }
    
        .references__online-link {
        word-break: break-all;
        margin-right: 1rem;
        }
    
        .references__return-link {
        display: inline-block;
        text-decoration: none;
        background-image: none;
        }
    
        .references__icon-return {
        transform: rotate(180deg);
        width: 16px;
        height: auto;
        }
    }
}