.blockquotes-section {
	.blockquote {
		margin-top: 5rem;
		margin-bottom: 8rem;
	}
	&--double {
		.blockquote {
			@include breakpoint(medium) {
				width: 50%;
				float: left;
				@include box;
				clear: none;
			}
			@include breakpoint(large) {
				margin-right: 0;
			}
		}	
	}
}

.section-blockquote {
	.blockquote {
		margin-bottom: 0;
		float: none;
	}

	@include breakpoint(large) {
		.blockquote {
			margin-left: auto;
			margin-right: 16.666666%;
		}
	}
}