.links {
	@extend %section-margins;
	&--1-col, &--related-content, &--blue {
		border-top: 1px solid $slate-55;
		background-color: $slate-05;
	}
	&--our-current-projects, &--yellow, &--green, .employer-toolkit & {
		border-top: 1px solid $green;
		background-color: $green-10;
	}
	&--1-col {
		border-top: 1px solid $slate-55;
		background-color: $slate-05;
	}
	&__heading {
		@extend %gutters;
		margin-bottom: 5rem;
		@include big-heading-font;
	}
	&__link {
		@include clearfix;
		&:not(:last-of-type) {
			&:after {
				content: '';
				display: block;
				margin: 4rem $gutter-half-width 3rem;
				border-bottom: 1px solid $gray-15;
			}
		}
		// bit of bottom padding when these go into an owl carousel
		.owl-carousel:not(.off) & {
			padding-bottom: 4rem;
		}
		&__image {
			@extend %gutters;
			margin-bottom: 3rem;
			padding-top: 1rem;
			position: relative;
			&__img {
				display: block;
				width: 100%;
    		height: auto;
			}
			&__cat {
				@include small-heading-font;
				background: $new_text_color;
				color: $white;
				padding: .5rem 1rem;
				position: absolute;
				bottom: 0;
				left: $gutter-half-width;
				max-width: calc(100% - #{$gutter-half-width * 2});
				@include box;
  			overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;
				&--pink {
					color: $new_text_color;
					background: $pink;
				}
				&--green {
					background: $green-strong;
				}
			}
		}
		&__content {
			@extend %gutters;
			max-width: 528px + ($gutter-half-width * 2);
		}
		&__heading {
			@include medium-body-font;
			display: inline-block;
			margin-bottom: 1.5rem;
			color: $green-strong;
			.links--personal-stories & {
				@extend %blockquote-text;
				margin-bottom: 0;
			}
			a {
				@include link;
				//hide link underline
				background-image: none;
			}
		}
		&__date {
			@include small-text-font;
			margin: 1rem 0 2rem;
		}
		&__text {
			padding-bottom: 1rem;
			.links--personal-stories & p {
				@extend %blockquote-source;
			}
			p {
				@include small-body-font;
				margin-bottom: 1.5rem;
			}
		}
		.external-link-icon {
			vertical-align: -2px;
			margin-left: 6px;
			margin-right: -2px;
		}
	}
	@include breakpoint(medium) {
		&--1-col .links {
			&__heading {
				width: 50%;
				float: left;
			}
			&__link {
				&__image {
					float: right;
					width: 50%;
				}
				&__content {
					width: 50%;
				}
			}
		}
		&--2-col {
			.articles {
				display: flex;
				flex-wrap: wrap;
			}

			.links__link {
				float: none;
				flex: 0 0 50%;
				width: 50%;

				&:before,
				&:after {
					content: none;
				}
			}

			.article-content {
				display: flex;
				flex-direction: column;
				height: 100%;
				justify-content: space-between;
			}
		}
		&--3-col .links {
			&__link {
				&__image {
					float: right;
					width: 50%;
				}
				&__content {
					width: 50%;
				}
			}
		}
	}
	@include breakpoint(large) {
		&--3-col {
			padding-bottom: 0;

			.articles {
				display: flex;
				flex-wrap: wrap;
			}

			.links__link {
				float: none;
				flex: 0 0 33.333333%;
				width: 33.333333%;
				margin-bottom: 8rem;
				
				&:before,
				&:after {
					content: none;
				}
				
				&__image, &__content {
					width: 100%;
					float: none;
				}
			}

			.article-content {
				display: flex;
				flex-direction: column;
				height: 100%;
				justify-content: space-between;
			}
		}
	}
}
