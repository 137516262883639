.form-wrap {
	border-top: 1px solid $slate-55;
	background: $slate-05;
	@extend %section-margins;
	&__content {
		@extend %text-column;
		margin-bottom: 5rem;
		&:last-child {
			margin-bottom: 0;
		}
	}
	&__heading {
		@include medium-heading-font;
		@extend %gutters;
		margin-bottom: 5rem;
	}
	&__p {
		@extend %gutters;
	}
}
