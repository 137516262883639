.stats {
	@extend %section-margins;
	&--blue {
		background-color: $white;
		border-top: 1px solid $slate-55;
	}
	&__heading {
		@extend %gutters;
		@include big-heading-font;
		margin-bottom: 5rem;
		.stats--blue & {
			color: $new_text_color;
		}
	}
	&__stat {
		@extend %gutters;
		&__icon {
			width: 20rem;
			height: 20rem;
			margin-bottom: 3rem;
		}
		&__figure, &__text {
			max-width: $sidebar-max-width;
		}
		&__figure {
			@include big-stat-font;
			color: $slate;
			margin-bottom: 1rem;
			.stats--blue & {
				color: $slate;
			}
		}
		&__text {
			@include body-bold-font;
			margin-bottom: 4rem;
			.stats--blue & {
				color: $new_text_color;
			}
		}
	}
	@include breakpoint(smallish) {
		&__stat:last-of-type &__stat__text {
			margin-bottom: 0;
		}
	}
	@include breakpoint(large) {
		&__heading--1, 
		&__heading--2 {
			width: 50%;
			margin-right: 16.666666%;
			margin-left: auto;
		}

		&__list {
			display: flex !important;
		}

		&__list--1, 
		&__list--2 {
			width: 66.666666%;
			// margin-right: 16.666666%;
			margin-left: 33.333333%;

			li {
				width: 50%;
			}
		}

		&__stat {
			width: 33.333333%;
			&__text {
				margin-bottom: 0;
			}
		}
	}
}
