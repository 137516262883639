.blockquote-carousel {
	@extend %section-margins;
	&__item {
		padding-bottom: 2rem;
		@include clearfix;
	}
	&__image {
		@extend %gutters;
		&__img {
			display: block;
			margin-bottom: 3rem;
		}
	}
	@include breakpoint(medium) {
		&__item {
			padding-bottom: 5rem;
		}
		&__image {
			width: 50%;
			float: left;
		}
	}
	@include breakpoint(large) {
		.blockquote__text {
			font-size: 4rem;
			//line-height: 1.25em;
			margin-bottom: 2rem;
		}
	}
	// @include breakpoint(huge) {
	// 	.blockquote__text {
	// 		font-size: 5rem;
	// 	}
	// }
}
