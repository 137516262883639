%btn {
	display: inline-block;
	margin-top: 1rem;
	margin-bottom: .5rem;
	padding: 1rem 3rem 1.25rem;
	border-radius: .5rem;
	background: $green-strong;
	box-shadow: 0 .5rem 0 0 $slate;
	transition: all 150ms ease;
	color: #fff;
	text-align: center;
	@include button-font;
	-webkit-appearance: none;
	cursor: pointer;
	&:hover {
		background: $slate;
		box-shadow: 0 .5rem 0 0 $new_text_color;
	}
	&:active {
		margin-top: 1.5rem;
		margin-bottom: 0px;
		background: $slate;
		box-shadow: none;
	}
	&:disabled {
		opacity: .5;
		&:hover {
			background-color: $slate;
		}
	}
	&--cta {
		margin-top: 0;
		margin-bottom: 0;
		box-shadow: none;
		&:active {
			margin-top: 0;
			background-color: $white;
			color: $new_text_color;
		}
	}
	&.is-loading {
		.btn__icon--plus {
			display: none;
		}
		.btn__icon--loading {
			display: block;
		}
	}
}
.btn {
	@extend %btn;
	&__icon {
		float: left;
		margin: .875rem 1.5rem 0 0;
		&--loading {
			animation: infinite-spinning 1s linear infinite;
			display: none;
		}
	}
}

@keyframes infinite-spinning {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}
