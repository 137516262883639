.event-signup {
	padding-bottom: 8rem;
	&__heading {
		@extend %text-column;
		@extend %gutters;
		margin-bottom: 5rem;
		@include big-heading-font;
	}
	&__intro {
		@extend %text-column;
		@extend %gutters;
		margin-bottom: 3rem;
	}
	&__content {
		@extend %text-column;
	}
	.cta+& {
		padding-top: 8rem;
	}
}
