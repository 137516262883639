.sponsor-logo {
    display: block;
    margin-top: 1rem;
    margin-bottom: 3rem;
    border: 2px solid $slate-10;
    width: fit-content;
    
    figure {
        margin: 0; 
        padding: $gutter-half-width;
    }

    img {
        width: 132px;
        max-width: 100%;
    }
}