.donate-payment {
	&__summary {
		@extend %sidebar-column;
		@extend %gutters;
		padding-top: 1rem;
		margin-bottom: 5rem;
		&__content {
			background-color: $light-blue;
			border: 1px solid $blue;
			padding: 3rem;
		}
		&__heading {
			@include body-bold-font;
		}
	}
	&__content {
		@extend %text-column;
	}
}