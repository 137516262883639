.asd-header {
	background-color: $asd-purple;
	height: 50px;
	border-bottom: 1px solid #FFF;
  @include breakpoint(large){
    height: 80px;
  }
	.asd-header--inner {
		max-width: 1296px;
		box-sizing: border-box;
		padding: 0 24px;
    @include breakpoint(smallish){
      padding: 0 48px;
    }
		margin: 0 auto;
    display: flex;
		.asd-header--logos {
      padding: 14px 0 0;
      flex: 1 0 auto;
      .asd-uk-header-title-full {
        color: #FFF;
        display: none;
      }
      .asd-uk-header-title-small {
        color: #FFF;
      }
      @include small-text-font;
      #newcastle_logo {
        width: 61px;
        height: auto;
        vertical-align: middle;
      }
      .divider {
        border-right: 1px solid #FFF;
        height: 16px;
        display: inline-block;
        margin: 0 16px;
      }
      @include breakpoint(large){
        padding: 14px 0 0;
        @include big-heading-font;
        #newcastle_logo {
          width: 122px;
          height: 40px;
        }
        .divider {
          height: 30px;
        }
        .asd-uk-header-title-full {
          display: inline;
        }
        .asd-uk-header-title-small {
          display: none;
        }
      }
		}
		.asd-header--text {
      flex: 0 1 auto;
			color: #FFF;
			padding: 14px 0 0;
      @include small-text-font;
      @include breakpoint(large){
        padding: 24px 0 0;
        @include body-bold-font;
      }
			a {
				color: inherit;
			}
		}
	}
}
