.links-see-more {
	background-color: $green-10;
	border-top: 1px solid $green;
	padding: 5rem 0 6rem;
	&--blue {
		background-color: $slate-05;
		border-top: 1px solid $slate-55;
	}
	&--white {
		background-color: $white;
		border-top: 1px solid $slate-30;
	}
	&__btn {
		@extend %gutters;
		text-align: center;
		.btn__icon {
			path {
				stroke: $white;
			}
		}
	}
}
